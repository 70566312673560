import React, { useState, useEffect } from 'react'

export const EVENT_SHOW_NOTIFICATION = 'showNotification'

export const Notification: React.FC = () => {
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [notificationType, setNotificationType] = useState<'info' | 'warning'>('info')

  useEffect(() => {
    const showNotification: any = (msg?: string, durationMs?: number) => {
      setMessage(msg)
      setTimeout(() => setMessage(undefined), durationMs || 5000)
    }
    document.addEventListener(EVENT_SHOW_NOTIFICATION, (e: CustomEvent | any) => {
      setNotificationType(e.detail?.type || 'info')
      showNotification(e.detail?.message, e.detail?.type === 'warning' ? 60000 : 5000)
    })
    return () => {
      document.removeEventListener(EVENT_SHOW_NOTIFICATION, showNotification)
    }
  }, [])

  return (
    <div>
      <div className={`notification-container ${!!message ? 'active' : ''} ${notificationType}`}>
        <div>{message && message.length > 0 ? message : ''}</div>
        <div className="cursor-pointer" onClick={() => setMessage(undefined)}>
          &times;
        </div>
      </div>
    </div>
  )
}
