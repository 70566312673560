import React, { Fragment } from 'react'
import { Fn } from 'depoto-core/dist/src/models'
import { Menu, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  title?: string
  selectedId?: number | string
  options: any[]
  hasAllOption?: boolean
  isDownDirection?: boolean
  isAnchorRight?: boolean
  onChange: Fn
}
export const Dropdown: React.FC<Props> = ({
  title,
  selectedId,
  options = [],
  isDownDirection,
  isAnchorRight = true,
  hasAllOption = true,
  onChange,
}) => {
  const selectedOption = selectedId ? options?.filter(o => o.id === selectedId)[0] : null
  if (!title) {
    title = selectedOption ? selectedOption.name : 'Vše'
  }
  return (
    <div>
      {/*<Menu as="div" className="relative inline-block text-left bg-white">*/}
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full py-2 gap-2">
            {title}
            <FontAwesomeIcon icon={['far', 'chevron-down']} size="lg" className="self-center text-primary-blue" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          {/*bottom-8*/}
          <Menu.Items
            style={{ width: 370, maxHeight: 550, bottom: isDownDirection ? '2rem' : '' }}
            className={`absolute ${
              isAnchorRight ? 'right' : 'left'
            }-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 overflow-y-auto focus:outline-none z-10`}>
            <div className="px-1 py-1">
              {hasAllOption && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => onChange(undefined)}
                      className={'group flex rounded-md items-center w-full px-2 py-2'}
                      style={{ fontWeight: active || selectedId === undefined ? 'bold' : 'normal' }}>
                      {(selectedId === undefined || selectedId === 'all') && (
                        <FontAwesomeIcon icon={['far', 'chevron-right']} size="lg" className={'mr-2'} />
                      )}
                      Vše
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
            {options.map((o, i) => (
              <div style={{ borderTopWidth: 0 }} key={`${o.id}_${o.name}_${i}`}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => onChange(o.id)}
                      className={'flex justify-start items-center w-full px-2 py-2 gap-2'}
                      style={{
                        backgroundColor: o.color,
                        fontWeight: active || selectedId === o.id ? 'bold' : 'normal',
                      }}>
                      {selectedId === o.id && <FontAwesomeIcon icon={['far', 'chevron-right']} size="lg" />}
                      {o.name}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
