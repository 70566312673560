import { CoreReducer, CoreSliceType } from './core'
import { LoginReducer, LoginSliceType } from './login'
import { loader, logger, persister } from './middleware'

export type StateType = {
  core: CoreSliceType
  login: LoginSliceType
}

const loadedState = loader()

export const initialState: StateType = {
  ...{
    core: CoreReducer.initialState,
    login: LoginReducer.initialState,
  },
  ...loadedState,
}

export const MainReducer = (state: StateType, action: any) => {
  // Receiving previous state here
  const { core, login } = state

  // Receiving current state here
  const nextState = {
    core: CoreReducer.reducer(core, action),
    login: LoginReducer.reducer(login, action),
  }

  // Middlewares
  // logger(action, state, nextState)
  persister(nextState)

  return nextState
}
