import { useCallback, useRef, useState } from 'react'

export function useIntersect() {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const measureRef = useCallback(node => {
    if (node) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      })

      observer.observe(node)
      return () => {
        observer.unobserve(node)
      }
    }
  }, [])

  return { measureRef, isIntersecting }
}
