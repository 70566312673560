import React, { useState } from 'react'
import { FormTextarea } from './FormTextarea'
import { Order } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { useCore } from '../hooks'

type Props = {
  order: Order
  onUpdate: Fn
}
export const OrderNotes: React.FC<Props> = ({ order, onUpdate }) => {
  const [note, setNote] = useState<string>(order.note)
  const [privateNote, setPrivateNote] = useState<string>(order.privateNote)
  const { isFetching, orderUpdates, setOrderUpdates } = useCore()

  const updateNote = (n: string) => {
    setOrderUpdates({ ...orderUpdates, id: order.id, note: n })
    setNote(n)
  }

  const updatePrivateNote = (n: string) => {
    setOrderUpdates({ ...orderUpdates, id: order.id, privateNote: n })
    setPrivateNote(n)
  }

  return (
    <div>
      <div className={'p-main flex gap-8'}>
        <div className={'flex-1 flex flex-col gap-4'}>
          <FormTextarea
            label={'Poznámka k objednávce'}
            textAreaCssClass={'h-56'}
            value={note}
            onChange={val => updateNote(val)}
          />
        </div>
        <div className={'flex-1 flex flex-col gap-4'}>
          <FormTextarea
            label={'Interní poznámka k objednávce'}
            textAreaCssClass={'h-56'}
            value={privateNote}
            onChange={val => updatePrivateNote(val)}
          />
        </div>
      </div>
    </div>
  )
}
