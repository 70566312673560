import React from 'react'
import { Link } from 'react-router-dom'

export const PublicHome = () => (
  <div>
    Depoto Expeditions v2 public page
    <br />
    <Link to="/login">Login</Link>
    <br />
    <Link to="/dashboard">Dashboard</Link>
  </div>
)
