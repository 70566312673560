import { StateType } from './reducers'
import { CoreReducer } from './core'
import { LoginReducer } from './login'

export const loader = (): Partial<StateType> | null => {
  const loadedStateString = window.localStorage.getItem('globalState')
  const loadedState =
    typeof loadedStateString === 'string' && loadedStateString?.length > 3
      ? ((<unknown>JSON.parse(loadedStateString)) as StateType)
      : null
  const state = {
    core: CoreReducer.initialState,
    login: loadedState?.login?.user ? loadedState.login : LoginReducer.initialState,
  }
  window.depotoEnv = <'prod' | 'stage' | 'dev' | 'custom'>state.core.core.services.oauth.session.clientType
  state.core.childCompanyId = loadedState?.core?.childCompanyId || 0
  return state
}

export const persister = async (state: Partial<StateType> | any) => {
  const stateWithoutCore: any = {
    login: state.login,
    core: {
      childCompanyId: state.core.childCompanyId || 0,
    },
  }
  await window.localStorage.setItem('globalState', JSON.stringify(stateWithoutCore))
}

export const asyncer = (dispatch: any, state: any) => (action: any) =>
  typeof action === 'function' ? action(dispatch, state) : dispatch(action)

export const logger = (action: object, prevState: object, currentState: object) => {
  console.groupCollapsed('Logger')
  console.log('%c Action:', 'color: blue', action)
  console.log('%c Previous State:', 'color: red', prevState)
  console.log('%c Current State:', 'color: green', currentState)
  console.groupEnd()
  // console.log(action, prevState, currentState)
}
