import React, { FC } from 'react'
import { ReactComponent as BannerLogo } from '../assets/img/dt-expe_login.svg'

export const NotFound404: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <p className="flex-1 p-10 text-4xl font-black text-black">404; Stránka nenalezena</p>
      <BannerLogo />
    </div>
  )
}
