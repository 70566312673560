import React from 'react'

type Props = {
  label: string
  url: string
}

export const LinkExternal: React.FC<Props> = ({ label, url }) => {
  const desktopApp: any = window._depoto
  return desktopApp ? (
    <a href="#" className="text-primary-blue font-semibold" onClick={() => desktopApp.nw.openLinkInDefaultBrowser(url)}>
      {label}
    </a>
  ) : (
    <a href={url} target="_blank" className="text-primary-blue font-semibold">
      {label}
    </a>
  )
}
