import React, { useState } from 'react'
import { Carrier, Currency, Order, Payment, PaymentItem } from 'depoto-core/dist/src/entities'
import { Btn } from './Btn'
import { FormSelect } from './FormSelect'
import { FormInput } from './FormInput'
import { useCore } from '../hooks'
import { Fn } from 'depoto-core/dist/src/models'

type Props = {
  order: Order
  onUpdate: Fn
}
export const CarrierCurrencyPayment: React.FC<Props> = ({ order, onUpdate }) => {
  const [carrier, setCarrier] = useState(order.carrier?.id)
  const [currency, setCurrency] = useState(order.currency?.id)
  const [paymentItems, setPaymentItems] = useState(order.paymentItems || [])
  const { carriers, currencies, payments, isFetching, orderUpdates, setOrderUpdates } = useCore()
  const loadedAt = +new Date()

  const updateCarrier = (c: string) => {
    setCarrier(c)
    setOrderUpdates({ ...orderUpdates, id: order.id, carrier: new Carrier({ id: c }) })
  }

  const updateCurrency = (c: string) => {
    setCurrency(c)
    setOrderUpdates({ ...orderUpdates, id: order.id, currency: new Currency({ id: c }) })
  }

  const addPaymentItem = () => {
    const nextPaymentItems = [...paymentItems]
    nextPaymentItems.push(new PaymentItem({ payment: payments[0] }))
    setPaymentItems(nextPaymentItems)
  }

  const updatePaymentItem = (paymentItem: PaymentItem, payment?: Payment, amount?: number) => {
    const nextPaymentItems = []
    for (const pi of paymentItems) {
      if (pi.uuid === paymentItem.uuid) {
        nextPaymentItems.push({
          ...paymentItem,
          payment: payment && payment.id > 0 ? payment : paymentItem.payment,
          amount: amount !== undefined ? Number(amount) : paymentItem.amount,
        })
      } else {
        nextPaymentItems.push(pi)
      }
    }
    setPaymentItems(nextPaymentItems)
    updatePaymentItems(nextPaymentItems)
  }

  const removePaymentItem = (paymentItem: PaymentItem) => {
    const nextPaymentItems = []
    for (const pi of paymentItems) {
      if (pi.uuid !== paymentItem.uuid) {
        nextPaymentItems.push(pi)
      }
    }
    setPaymentItems(nextPaymentItems)
  }

  const updatePaymentItems = (nextPaymentItems: PaymentItem[]) => {
    if (loadedAt + 1000 < +new Date()) {
      setOrderUpdates({ ...orderUpdates, id: order.id, paymentItems: nextPaymentItems })
    }
  }

  const carrierOptions = (carriers || []).map(c => ({ label: c.name, value: c.id }))
  const uniqueCarriers = Array.from(new Map(carrierOptions.map(item => [item['value'], item])).values())

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'box flex flex-col gap-3'}>
        <h1 className={'text-black text-base font-semibold'}>Dopravce</h1>
        <div className={'flex gap-3'}>
          <FormSelect cssClass={'flex-1'} value={carrier} options={uniqueCarriers} onChange={updateCarrier} />
        </div>
      </div>

      <div className={'box flex flex-col gap-3'}>
        <h1 className={'text-black text-base font-semibold'}>Měna</h1>
        <div className={'flex gap-3'}>
          <FormSelect
            cssClass={'flex-1'}
            value={currency}
            options={(currencies || []).map(c => ({ label: c.name, value: c.id }))}
            onChange={updateCurrency}
          />
        </div>
      </div>

      <div className={'box flex flex-col gap-3'}>
        <div className={'flex justify-between items-center'}>
          <h1 className={'text-black text-base font-semibold'}>Platební metoda</h1>
          <Btn
            cssClass={'btn-outline border-light-grey'}
            children={'Přidat platební metodu'}
            title={'Přidat platební metodu'}
            icon={'plus'}
            isDisabled={isFetching}
            isLoading={isFetching}
            onClick={addPaymentItem}
          />
        </div>
        {paymentItems.map(pi => (
          <div className={'flex items-center gap-3'} key={`${pi.uuid}`}>
            <FormSelect
              cssClass={'flex-1'}
              value={pi.payment?.id}
              options={(payments || []).map(p => ({ label: p.name, value: p.id }))}
              onChange={val => updatePaymentItem(pi, payments.filter(p => p.id == val)[0], pi.amount)}
            />
            <div className={'self-end flex-1'}>
              <FormInput
                type={'number'}
                inputCssClass={'text-right'}
                value={`${pi.amount || 0}`}
                onChange={val => updatePaymentItem(pi, pi.payment, val)}
              />
            </div>
            <div className={'self-end'}>
              <Btn
                cssClass={'btn-danger btn-circle'}
                icon={'trash-alt'}
                title={'Smazat'}
                isDisabled={isFetching}
                isLoading={isFetching}
                onClick={() => removePaymentItem(pi)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
