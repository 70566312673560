import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Schema } from '../lib'
import { Order, Package, PackageDisposal } from 'depoto-core/dist/src/entities'
import { Btn, Loader, Group as GroupComponent, Dropdown, DateFormatted, EVENT_SHOW_NOTIFICATION } from '../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from '../components/Checkbox'
import { useCore } from '../hooks'

export const Dispatch = () => {
  // const [isFetching, setIsFetching] = useState<boolean>(false)
  const [selectedCarrier, setSelectedCarrier] = useState<string>()
  const [selectedCompany, setSelectedCompany] = useState<number>()
  const [packages, setPackages] = useState<Package[]>([])
  const [packagesForDispatch, setPackagesForDispatch] = useState<number[]>([])
  const [ordersToUpdateProcessStatus, setOrdersToUpdateProcessStatus] = useState<number[]>([])
  const [orderUpdateProgress, setOrderUpdateProgress] = useState<string>('0.00')
  const { core, childCompanies, company, carriers, isFetching, setIsFetching } = useCore()
  const history = useHistory()

  useEffect(() => {
    if (childCompanies.length === 0) {
      setSelectedCompany(company?.id)
    }
  }, [])

  const getPackages = async (nextCompany?: number, nextCarrier?: string) => {
    if (!nextCompany || !nextCarrier) {
      return
    }
    setIsFetching(true)
    try {
      const packages = await core?.services.pack.getList(
        {
          filters: {
            companies: [nextCompany],
            carrier: nextCarrier,
            isSent: true,
            hasDisposal: false,
          },
        },
        Schema.package.detail,
      )
      setPackages(packages?.items?.length ? packages.items : [])
      if (packages.items?.length) {
        setPackagesForDispatch(packages.items.map((p: Package) => p.id))
      }
    } catch (errors) {
      document.dispatchEvent(
        new CustomEvent(EVENT_SHOW_NOTIFICATION, {
          detail: {
            message: `Chyba:\n${JSON.stringify(errors)}`,
            type: 'warning',
          },
        }),
      )
    }
    setIsFetching(false)
  }

  const togglePackageForDispatch = (packageId: number) => {
    let nextPFD: number[] = []
    if (packagesForDispatch.includes(packageId)) {
      for (const pId of packagesForDispatch) {
        if (pId !== packageId) {
          nextPFD.push(pId)
        }
      }
    } else {
      nextPFD = [...packagesForDispatch, packageId]
    }
    setPackagesForDispatch(nextPFD)
  }

  const createDisposal = async () => {
    if (!selectedCarrier || !packages?.length) {
      return
    }
    setIsFetching(true)
    try {
      setOrdersToUpdateProcessStatus([])
      setOrderUpdateProgress('0.00')
      const orders = []
      for (const packId of packagesForDispatch) {
        const orderId = packages.find(p => p.id === packId)?.order?.id
        if (orderId) {
          orders.push(orderId)
        }
      }
      const uniqueOrders = Array.from(new Set(orders))
      setOrdersToUpdateProcessStatus(uniqueOrders)
      let i = 0
      for (const orderId of uniqueOrders) {
        await core?.services.order.updateProcessStatus(
          new Order({ id: orderId }),
          'dispatched',
          'auto updated from expedition3',
          { id: null },
        )
        i++
        setOrderUpdateProgress(`${(i / (uniqueOrders.length / 100)).toFixed(2)}`)
      }
      await core?.services.pack.createDisposal(
        { id: selectedCompany },
        { id: selectedCarrier },
        packagesForDispatch.map(id => ({ id })),
        Schema.packageDisposal.list,
      )
    } catch (errors) {
      document.dispatchEvent(
        new CustomEvent(EVENT_SHOW_NOTIFICATION, {
          detail: {
            message: `Chyba:\n${JSON.stringify(errors)}`,
            type: 'warning',
          },
        }),
      )
    }
    setIsFetching(false)
    await getPackages(selectedCompany, selectedCarrier)
    setTimeout(() => history.push('/dispatch'), 10)
  }

  return isFetching ? (
    <Loader size="fill-container" />
  ) : (
    <div>
      <div className="p-3">
        <p className="font-bold text-xl mb-2">Vytvořit svoz</p>
        <div className="flex">
          <div className="flex items-center gap-2 mr-24">
            <div className="font-bold mr-5">Společnost</div>
            <FontAwesomeIcon icon={['far', 'house']} className="text-primary-blue self-center" />
            <Dropdown
              selectedId={selectedCompany}
              options={childCompanies.length > 0 ? childCompanies : [company]}
              isAnchorRight={false}
              hasAllOption={false}
              onChange={val => {
                setSelectedCompany(val)
                getPackages(val, selectedCarrier)
              }}
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="font-bold mr-5">Dopravce</div>
            <FontAwesomeIcon icon={['far', 'truck']} className="text-primary-blue self-center" />
            <Dropdown
              selectedId={selectedCarrier}
              options={carriers || []}
              isAnchorRight={false}
              onChange={async val => {
                setSelectedCarrier(val)
                await getPackages(selectedCompany, val)
              }}
            />
          </div>
          <Btn
            cssClass={'btn-primary ml-auto'}
            icon={'fa-truck-ramp'}
            children={`Vytvořit${packagesForDispatch.length > 0 ? ` (${packagesForDispatch.length} balíků)` : ''}`}
            title={'Vytvořit'}
            isDisabled={isFetching || !packagesForDispatch?.length}
            isLoading={isFetching}
            onClick={createDisposal}
          />
        </div>
      </div>
      {ordersToUpdateProcessStatus.length > 0 && (
        <div style={{ backgroundColor: 'red', width: `${orderUpdateProgress}%` }}>
          {orderUpdateProgress}% (počet objednávek: {ordersToUpdateProcessStatus.length || '0'})
        </div>
      )}
      <div className="flex flex-col">
        <div className="grid grid-cols-6 gap-1 pt-3 pb-3">
          <div className="font-bold">Kód balíku</div>
          <div className="font-bold">Příjemce</div>
          <div className="font-bold">Kontakt</div>
          <div className="font-bold">Adresa</div>
          <div className="font-bold">PSČ / stát</div>
          <div className="font-bold flex flex-row">
            <Btn
              cssClass={'btn-secondary btn-xs ml-auto'}
              icon={'fa-check-double'}
              children={'Označit vše'}
              title={'Označit vše'}
              onClick={() => {
                if (packages.length === packagesForDispatch.length) {
                  setPackagesForDispatch([])
                } else {
                  setPackagesForDispatch(packages.map((p: Package) => p.id))
                }
              }}
            />
          </div>
        </div>
        {packages.length > 0 &&
          packages.map(p => (
            <div
              className="grid grid-cols-6 gap-1 p-1"
              style={{ borderBottom: '1px solid #e5e5e5' }}
              key={`package_${p.id}`}>
              <div>{p.code}</div>
              <div>
                {p.order?.shippingAddress?.firstName} {p.order?.shippingAddress?.lastName}
              </div>
              <div>
                {p.order?.shippingAddress?.email} {p.order?.shippingAddress?.phone}
              </div>
              <div>
                {p.order?.shippingAddress?.street} {p.order?.shippingAddress?.city}
              </div>
              <div>
                {p.order?.shippingAddress?.zip} {p.order?.shippingAddress?.country}
              </div>
              <div className="flex">
                <div className={'col-start-1 col-end-3 place-self-center self-center ml-auto'}>
                  <Checkbox
                    checked={packagesForDispatch.includes(p.id)}
                    onChange={() => togglePackageForDispatch(p.id)}
                    disabled={isFetching}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
