import React from 'react'
import { SideMenu } from '../components'
import { useParams } from 'react-router-dom'

type Props = {
  children: any
  hasMainHeader: boolean
  isSettingsView?: boolean
}
export const Main: React.FC<Props> = ({ children, hasMainHeader, isSettingsView }) => {
  const { processStatus }: any = useParams()
  return <SideMenu children={children} hasMainHeader={hasMainHeader} isSettingsView={isSettingsView} />
}
