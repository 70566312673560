import React, { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  id?: string
  children?: React.ReactNode
  cssClass: string
  isDisabled?: boolean
  icon?: any
  isLoading?: boolean
  onClick?: () => void
  title?: string
}

export const Btn = forwardRef<HTMLButtonElement, Props>(
  ({ id, cssClass, children, icon, isLoading, isDisabled, onClick, title }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        id={id}
        onClick={onClick}
        className={isLoading ? cssClass + ' loading ' + 'btn' : cssClass + ' btn gap-1'}
        title={title}
        disabled={isDisabled}>
        {!isLoading && icon ? <FontAwesomeIcon icon={['far', icon]} size="lg" /> : null}
        {children}
      </button>
    )
  },
)
