import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SizeProp, IconName } from '@fortawesome/fontawesome-svg-core'
import { Btn } from './Btn'

type Props = {
  status: string
  size?: SizeProp
  className?: string
}
export const ProcessStatusIcon: React.FC<Props> = ({ status, size, className }) => {
  let icon: IconName
  switch (status) {
    case 'received':
    case 'recieved':
    default:
      icon = 'cart-plus'
      break
    case 'picking':
      icon = 'conveyor-belt'
      // icon = 'scanner-gun' ??
      break
    case 'packing':
      icon = 'box-full'
      break
    case 'packed':
      icon = 'pallet-alt'
      break
    case 'dispatched':
      icon = 'truck'
      break
    case 'delivered':
      icon = 'check-circle'
      break
    case 'picking_error':
      icon = 'exclamation-triangle'
      break
    case 'returned':
      icon = 'exclamation-triangle' // todo
      break
    case 'cancelled':
      icon = 'exclamation-triangle' // todo
      break
  }
  return <FontAwesomeIcon icon={['far', icon]} size={size} className={className} />
}
