import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  type: 'fragile' | 'oversize'
  isIconOnly?: boolean
  isInline?: boolean
  marginLeft?: number
}

export const Pictogram: React.FC<Props> = ({ type, isIconOnly = false, isInline = false, marginLeft = 0 }) => {
  const iconClassName = type === 'fragile' ? 'fragile' : 'expand-alt'
  const colorClassName = type === 'fragile' ? 'label-yellow' : 'label-purple'
  return (
    <div className={`self-center ${isInline ? 'inline-block' : ''} ${marginLeft > 0 ? `ml-${marginLeft}` : ''}`}>
      <span className={colorClassName}>
        <FontAwesomeIcon icon={['far', iconClassName]} size="lg" className={'mr-2'} />
        {!isIconOnly && <span>{type === 'fragile' ? 'Křehké' : 'Nadměrné'}</span>}
      </span>
    </div>
  )
}
