import { OAuthSession } from 'depoto-core/dist/src/models'
import { DepotoCore } from 'depoto-core'
import { User } from 'depoto-core/dist/src/entities'
import { wait } from '../../lib'

export enum LOGIN {
  INIT = 'LOGIN_INIT',
  SUCCESS = 'LOGIN_SUCCESS',
  ERROR = 'LOGIN_ERROR',
  TERMINATE = 'LOGIN_TERMINATE',
  LOGOUT = 'LOGIN_LOGOUT',
}

const PROFILE_FETCH_ATTEMPTS = 3

const authenticate = async (
  email: string,
  password: string,
  environment: string,
  customUrl: string,
  core: DepotoCore,
) => {
  const session: OAuthSession = new OAuthSession({
    email: email,
    password,
    clientType: environment,
    isRememberMe: true,
  })
  if (environment === 'custom' && customUrl?.length > 0) {
    session.clientCustomUri = customUrl
  }
  session.clientId = '26_24166lmf1t0k00gogck8gsgc88og0848g88owwoc4w0404w4og' // todo
  session.clientSecret = '1ccidhta12pwk84ocow0wg00osksgk8oow8kksssc8gsc4og0k'
  try {
    return await core.services.oauth.getToken(session)
  } catch (e) {
    console.warn(e)
  }
}

function login() {
  return {
    type: LOGIN.INIT,
  }
}

function success(user: User) {
  return {
    type: LOGIN.SUCCESS,
    payload: { user },
  }
}

function failed(error: string) {
  return {
    type: LOGIN.ERROR,
    payload: error,
  }
}

function terminate() {
  return {
    type: LOGIN.TERMINATE,
  }
}

export function handleLogin(email: string, password: string, environment: string, customUrl: string, core: DepotoCore) {
  return async function (dispatch: any) {
    dispatch(login())
    try {
      if (customUrl?.length > 7) {
        window.localStorage.setItem('depoto_server_custom_url', customUrl)
      } else {
        window.localStorage.removeItem('depoto_server_custom_url')
      }
      const isAuth = await authenticate(email, password, environment, customUrl, core)
      if (isAuth) {
        await core.services.user.setCurrentUser(email)
        if (core.services.user.user) {
          window.depotoEnv = <'prod' | 'stage' | 'dev' | 'custom'>environment
          dispatch(success(core.services.user.user!))
          return true
        } else {
          dispatch(failed('profile fetch error'))
          return false
        }
      } else {
        dispatch(failed('login error'))
        return false
      }
    } catch (error: any) {
      dispatch(failed(error.response.data))
      return false
    }
  }
}

export const handleAuthInit = (core: DepotoCore) => async (dispatch: any) => {
  const isAuth = core.services.oauth.isAuthenticated()
  console.log('handleauthinit')
  // Let the state storage remain it the initial state
  if (!isAuth || !core.services.oauth?.session?.token) {
    return
  }

  core.services.oauth.session.clientId = '26_24166lmf1t0k00gogck8gsgc88og0848g88owwoc4w0404w4og'
  core.services.oauth.session.clientSecret = '1ccidhta12pwk84ocow0wg00osksgk8oow8kksssc8gsc4og0k'

  const { token, email } = core.services.oauth?.session || {}
  const { timestamp, expiresIn } = token || {}

  // Check if access token is expired by expiresIn field
  if (timestamp && expiresIn && Date.now() - timestamp > (expiresIn - 60) * 1000) {
    const isRefreshed = await core.services.oauth.getRefreshToken()

    if (!isRefreshed) {
      dispatch(terminate())
      return
    }
  }

  // Let the core to fetch the user profile 3 times
  // There is a condition when accessToken is expired earlier that expiresIn
  // and this is braking above refresh logic. f.e. when expiring token directly from DB

  for (let i = 0; i < PROFILE_FETCH_ATTEMPTS; i++) {
    await core.services.user.setCurrentUser(email)
    const user = core.services.user.user
    if (user) {
      dispatch(success(user))
      break
    } else {
      await wait(300)
    }
  }
}

export function handleLogout(core: DepotoCore) {
  // We don't need to logout if we are not authenticated
  if (core.services?.oauth?.session?.isAuthenticated) {
    core.services.oauth.logout()
  }

  return {
    type: LOGIN.LOGOUT,
  }
}
