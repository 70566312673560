import React from 'react'
import { PhotoSwipe } from './PhotoSwipe'
import { Pictogram } from './Pictogram'
import { ReturnItem } from '../store/core/reducer'
import { useCore } from '../hooks'
import { Btn } from './Btn'
import { formatDate } from '../lib'

type Props = {
  returnItem: ReturnItem
}

export const ReturnItemRow: React.FC<Props> = ({ returnItem }) => {
  const { currentOrder, depots, orderReturns, setOrderReturns } = useCore()
  const thumbUrl = returnItem.product?.mainImage?.thumbnails?.length
    ? returnItem.product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = returnItem.product?.mainImage?.thumbnails?.length
    ? returnItem.product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  const removeReturnItem = () => {
    if (currentOrder?.id && orderReturns[currentOrder?.id]) {
      const nextOrderReturns = { ...orderReturns }
      nextOrderReturns[currentOrder?.id] = orderReturns[currentOrder?.id].filter(ri => ri.uuid !== returnItem.uuid)
      setOrderReturns(nextOrderReturns)
    }
  }

  return (
    <>
      <div className={`grid grid-cols-12 leading-10`}>
        <div className="grid grid-cols-6 col-span-6 gap-2">
          <div className="col-end-1 self-center flex items-center pl-20">
            <PhotoSwipe
              alt={''}
              caption={`
                        ${returnItem ? returnItem.product?.name : ''} <br> 
                        ${returnItem.product?.code ? 'Kód: ' + returnItem.product.code : ''} <br> 
                        ${returnItem.product?.ean ? 'EAN: ' + returnItem.product.ean : ''}`}
              galleryImg={imageUrl}
              src={thumbUrl}
              thumbnails={''}
            />
          </div>
          <div className="col-span-5 self-center font-semibold">
            {returnItem.product?.name}
            {returnItem.product?.isFragile ? <Pictogram type={'fragile'} isInline={true} marginLeft={2} /> : null}
            {returnItem.product?.isOversize ? <Pictogram type={'oversize'} isInline={true} marginLeft={2} /> : null}
          </div>
        </div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>
          {depots.find(d => d.id === returnItem.depotId)?.name || ''}
        </div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4 text-center'}>
          {returnItem.expirationDate ? formatDate(returnItem.expirationDate) : ''}
        </div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4 text-center'}>{returnItem.batch}</div>
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4 text-center'}>
          {returnItem.position1}-{returnItem.position2}-{returnItem.position3}
        </div>
        <div className="place-self-center self-center">
          <div className="square">
            <span className="square-amount">{returnItem.amount}</span>
          </div>
        </div>
        <div className="place-self-center self-center">
          <Btn cssClass={'btn-danger'} icon={'fa-times'} title={'Odebrat'} onClick={removeReturnItem} />
        </div>
      </div>
    </>
  )
}
