import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { Fn } from 'depoto-core/dist/src/models'

interface Props {
  cssClass?: string
  inputCssClass?: string
  isDisabled?: boolean
  placeholder?: string
  type?: 'text' | 'number' | 'password' | 'date'
  label?: string
  value?: string
  debounceMs?: number
  min?: number
  max?: number
  blurOnInit?: boolean
  onChange?: Fn
  onSubmit?: Fn
  onBlur?: Fn
}

// export const FormInput: React.FC<Props> = forwardRef<HTMLInputElement, Props>(({
export const FormInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      cssClass,
      isDisabled,
      inputCssClass,
      label,
      placeholder,
      type = 'text',
      children,
      value,
      debounceMs = 500,
      min,
      max,
      blurOnInit,
      onChange,
      onSubmit,
      onBlur,
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [inputValue, setInputValue] = useState<string>(value || '')
    const inputRef = useRef<HTMLInputElement>(null)
    // useEffect(() => {
    //   if (ref) {
    //     ref = inputRef
    //   }
    // }, [inputRef.current])

    useEffect(() => {
      if (blurOnInit && inputRef?.current) {
        setTimeout(() => inputRef?.current?.blur(), 50) // fukin helllllllll!!!!!! wtf how is this even remotely logical????!?!??!
      }
    }, [])

    useEffect(() => {
      const delayInputTimeoutId = setTimeout(() => {
        typeof onChange === 'function' ? onChange(inputValue) : null
      }, debounceMs)
      return () => clearTimeout(delayInputTimeoutId)
    }, [inputValue, debounceMs])

    useEffect(() => {
      setInputValue(value || '')
    }, [value])

    return (
      <div className={cssClass === undefined ? 'form-control' : cssClass + ' form-control '}>
        <label className={label === undefined ? 'none' : ''}>{label}</label>
        <input
          // ref={ref}
          ref={inputRef}
          type={type}
          placeholder={placeholder === undefined ? '' : placeholder}
          className={inputCssClass === undefined ? 'input' : inputCssClass + ' input'}
          disabled={isDisabled}
          value={inputValue}
          min={min}
          max={max}
          // onChange={ev => (typeof onChange === 'function' ? onChange(ev.target.value) : null)}
          onChange={ev => setInputValue(ev.target.value)}
          onKeyDown={ev => (ev.key === 'Enter' && typeof onSubmit === 'function' ? onSubmit() : null)}
          onBlur={ev => (typeof onBlur === 'function' ? onBlur(ev.target.value) : null)}
        />
      </div>
    )
  },
)
