import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, useParams, useLocation } from 'react-router-dom'
import { Footer, Loader, OrderNotes, OrderProducts, OrderShipping, OrderState } from '../components'
import { Order } from 'depoto-core/dist/src/entities'
import { Schema } from '../lib'
import { OrderFiles } from '../components/OrderFiles'
import { NotFound404 } from './NotFound404'
import { useCore } from '../hooks'
import { useQuery } from '@tanstack/react-query'

export const OrderDetail: React.FC<any> = () => {
  const { core, currentOrder, setCurrentOrder, isFetching, setIsFetching } = useCore()
  const { orderId }: any = useParams()
  const location: any = useLocation()
  const { path } = useRouteMatch()

  const {
    refetch,
    isLoading,
    isError,
    data: order,
  } = useQuery<Order>(
    ['order', orderId],
    async ({ queryKey }) => {
      const [, orderId] = queryKey
      if (orderId) {
        const response = new Order(await core?.services.order.getById(orderId as number, Schema.order.detail))
        setCurrentOrder(response)
        if (!response || response?.id <= 0) {
          throw new Error('Order not found')
        }
        return response
      } else {
        throw new Error('Order not found')
      }
    },
    { enabled: true, retry: 1 },
  )

  useEffect(() => {
    setCurrentOrder(order)
  }, [order])

  if (isLoading) return <Loader size="fill-container" />

  if (isError) return <NotFound404 />

  return order ? (
    <div className={'flex flex-col h-full justify-between'}>
      <Switch location={location}>
        <Route exact path={path}>
          <OrderProducts order={order} onUpdate={refetch} />
        </Route>
        <Route path={`${path}/items`}>
          <OrderProducts order={order} onUpdate={refetch} />
        </Route>
        <Route path={`${path}/shipping`}>
          <OrderShipping order={order} onUpdate={refetch} />
        </Route>
        <Route path={`${path}/notes`}>
          <OrderNotes order={order} onUpdate={refetch} />
        </Route>
        <Route path={`${path}/states`}>
          <OrderState order={order} />
        </Route>
        <Route path={`${path}/files`}>
          <OrderFiles order={order} onUpdate={refetch} />
        </Route>
      </Switch>
      <Footer onUpdate={refetch} />
    </div>
  ) : null
}
