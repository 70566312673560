import React, { useEffect, useState } from 'react'
import { Order, OrderItem, ProductMove, ProductMovePack, ProductBundle } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { OrderItemRow } from './OrderItemRow'
import { formatDate, getOrderItemsForReturns, Schema } from '../lib'
import { Btn } from './Btn'
import { useCore } from '../hooks'
import { OrderItemRowForReturns } from './OrderItemRowForReturns'
import { OrderItemForReturn } from '../store/core/reducer'
import { ReturnItemRow } from './ReturntemRow'
import { ProductMoveRow } from './ProductMoveRow'

type ItemsForReturn = {
  [orderItemId_productId: string]: {
    amount: number
    depotId: number
    batch: string
    expirationDate: string
    position1: string
    position2: string
    position3: string
    orderItemId: string | number
    productId: string | number
    note: string
  }
}
type Props = {
  order: Order
  onUpdate: Fn
}
// TODO! vrchni blok zobrazit vzdy, kdyz je empty tak s hlaskou ze je vse vraceno
// TODO 2! nahore btn pro vraceni vseho na puvodni pozice, bez potvrzeni (jen pokud jeste neexistuji vratky vubec..)
export const OrderItemsForReturns: React.FC<Props> = ({ order, onUpdate }) => {
  // const orderItems: OrderItemForReturn[] = getOrderItemsForReturns(order)
  // const [orderItems, setOrderItems] = useState<OrderItemForReturn[]>(getOrderItemsForReturns(order))
  const [orderItems, setOrderItems] = useState<OrderItemForReturn[]>([])
  const [itemsForReturn, setItemsForReturn] = useState<ItemsForReturn>({})
  const [oldReturnedOrders, setOldReturnedOrders] = useState<ProductMovePack[]>([])
  const { core, isFetching, setIsFetching, orderReturns, setOrderReturns } = useCore()

  useEffect(() => {
    core?.services.productMovePack
      .getList({ filters: { type: 'in', order: order.id } }, Schema.productMovePack.detail)
      .then((res: any) => {
        const relatedReturnedMovePacks = res.items.map((pmp: ProductMovePack) => ({
          ...pmp,
          moves: pmp.moves.map((pm: ProductMove) => ({
            ...pm,
            product: pm.productDepotTo?.product || pm.product,
            batch: pm.productDepotFrom?.batch || pm.productDepotTo?.batch,
            expirationDate: pm.productDepotFrom?.expirationDate || pm.productDepotTo?.expirationDate,
            position: pm.productDepotFrom?.position || pm.productDepotTo?.position,
            position1: pm.productDepotFrom?.position1 || pm.productDepotTo?.position1,
            position2: pm.productDepotFrom?.position2 || pm.productDepotTo?.position2,
            position3: pm.productDepotFrom?.position3 || pm.productDepotTo?.position3,
          })),
        }))
        setOldReturnedOrders(relatedReturnedMovePacks)
        setOrderItems(getOrderItemsForReturns(order, relatedReturnedMovePacks))
      })
    return () => {}
  }, [order])

  // const setForReturn = (returnItem: any) => {
  //   const nextItemsForReturn: ItemsForReturn = { ...itemsForReturn }
  //   nextItemsForReturn[`${returnItem.orderItemId}_${returnItem.productId}`] = returnItem
  //   if (returnItem.amount < 1) {
  //     delete nextItemsForReturn[`${returnItem.orderItemId}_${returnItem.productId}`]
  //   }
  //   // console.warn({ nextItemsForReturn })
  //   setItemsForReturn(nextItemsForReturn)
  //   const returnsDepotId =
  //     order?.checkout?.returnsDepot && order.checkout.returnsDepot.id > 0 ? order.checkout.returnsDepot.id : 0
  // }

  const separateReturnItemsByDepotsAndReturnThem = async () => {
    setIsFetching(true)
    const defaultReturnsDepotId =
      order?.checkout?.returnsDepot && order.checkout.returnsDepot.id > 0 ? order.checkout.returnsDepot.id : 0
    const movesByReturnDepots: any = {}

    const orderToReturn = new Order()
    orderToReturn.items = []
    const moves: ProductMove[] = []
    for (const oi of order.items) {
      const products = [oi.product, ...(oi.product?.bundleChildren.flatMap((pb: ProductBundle) => pb.child) || [])]
      for (const prod of products) {
        if (prod) {
          // const ret = itemsForReturn[`${oi!.id}_${prod.id}`]
          const ret = orderReturns[order.id].find(ri => ri.orderItem.id === oi.id && ri.product?.id === prod.id)
          console.log({ ret })
          if (ret) {
            const depotTo = ret.depotId ? Number(ret.depotId) : Number(defaultReturnsDepotId)
            if (!movesByReturnDepots[depotTo]) {
              movesByReturnDepots[depotTo] = []
            }
            movesByReturnDepots[depotTo].push(
              new ProductMove({
                product: { id: ret.product?.id },
                // supplier: oi.product?.supplier?.id, // nope!
                supplier: oi.moves[0]?.productDepotFrom?.supplier?.id,
                depotTo,
                amount: Number(ret.amount),
                orderItem: oi,
                position1: ret.position1 ? ret.position1 : null,
                position2: ret.position2 ? ret.position2 : null,
                position3: ret.position3 ? ret.position3 : null,
                batch: ret.batch ? ret.batch : null,
                note: ret.note ? ret.note : undefined,
                expirationDate: ret.expirationDate ? ret.expirationDate : null,
              }),
            )
          }
        }
      }
    }
    for (const depotId in movesByReturnDepots) {
      await createMovePackAndReturnItems(movesByReturnDepots[depotId])
    }

    // for (const pm of movePack.moves) {
    //   if (!pm.supplier) {
    //     // @ts-ignore
    //     delete pm.supplier
    //   }
    // }
    // console.log(movePack.moves)
    // console.log({ movesByReturnDepots })
    // const res = await core.services.productMovePack.create(movePack, 1, [], Schema.productMovePack.detail)
    // if (res?.id) {
    //   await core.services.order.updateProcessStatus(order, 'returned', `Naskladnění vratkou č. ${res.id}`)
    //   setItemsForReturn({})
    setIsFetching(false)
    onUpdate()
    // }
  }

  const createMovePackAndReturnItems = async (moves: ProductMove[]) => {
    const movePack: ProductMovePack = new ProductMovePack({
      type: { id: 'in' },
      // note: 'Naskladnění vratkou z objednávky č. ' + order.reservationNumber, // not for now, task #64
      order: { id: order.id },
      moves: moves,
    })

    for (const pm of movePack.moves) {
      if (!pm.supplier) {
        // @ts-ignore
        delete pm.supplier
      }
    }
    // console.log(movePack.moves)
    const res = await core?.services.productMovePack.create(movePack, Schema.productMovePack.detail)
    if (res?.id) {
      console.log({ res, movePack })
      // todo chytat vsechny chyby, ulozit a nechat zobrazit / exportovat do souboru
      await core.services.order.updateProcessStatus(order, 'returned', `Naskladnění vratkou`)
      const nextOrderReturns = { ...orderReturns }
      delete nextOrderReturns[order.id]
      setOrderReturns(nextOrderReturns)
      onUpdate()
    }
  }

  const getReturnedForOrderItem = (orderItemId: number): any[] => {
    const returnsForOrderItem = []
    for (const pmp of oldReturnedOrders) {
      returnsForOrderItem.push(...pmp.moves.filter((pm: ProductMove) => pm.orderItem?.id === orderItemId))
    }
    return returnsForOrderItem
  }

  const getMovesToReturn = (orderItemId: number): any[] => {
    // console.log('getMOvesForReturn UP', { orderItemId, itemsForReturn })
    const movesToReturn = []
    for (const oiIdProdId in itemsForReturn) {
      if (itemsForReturn[oiIdProdId].orderItemId === orderItemId) {
        movesToReturn.push(itemsForReturn[oiIdProdId])
      }
    }
    // console.log('getMOvesForReturn FIN', { movesToReturn })
    return movesToReturn
  }

  return (
    <div>
      <div>
        <div className="font-bold text-xl ml-5 mt-5">Dosud nevrácené položky</div>
        {orderItems.length > 0 ? (
          <div>
            <div className="grid grid-cols-12 leading-10">
              {/*HLAVICKA TABULKY*/}
              <div className="col-start-1 col-span-6 border-b font-medium text-shadow-grey pl-20">Název zboží</div>
              <div className="border-b font-medium text-shadow-grey">Kód</div>
              <div className="border-b font-medium text-shadow-grey">EAN</div>
              <div className="border-b font-medium text-shadow-grey text-center">Objednáno (ks)</div>
              <div className="border-b font-medium text-shadow-grey text-center">Vráceno (ks)</div>
              <div className="border-b font-medium text-shadow-grey text-center">Možno vrátit (ks)</div>
              <div className="border-b font-medium text-shadow-grey text-center">Vrátit</div>
            </div>
            <div className="table-stripped">
              {orderItems.map(oi => (
                <div key={`order-item-for-returns-${order.id}-${oi.id}`}>
                  <OrderItemRowForReturns orderItem={oi} onUpdate={() => null} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center">Všechny položky jsou vráceny</div>
        )}
      </div>
      {orderReturns[order.id]?.length > 0 ? (
        <div className="w-full">
          <div className="font-bold text-xl ml-5 mt-5">Rozpracovaná vratka</div>
          <div className="grid grid-cols-12 leading-10">
            {/*HLAVICKA TABULKY*/}
            <div className="col-start-1 col-span-6 border-b font-medium text-shadow-grey pl-20">Název zboží</div>
            <div className="border-b font-medium text-shadow-grey">Sklad</div>
            <div className="border-b font-medium text-shadow-grey">Expirace</div>
            <div className="border-b font-medium text-shadow-grey text-center">Šarže</div>
            <div className="border-b font-medium text-shadow-grey text-center">Pozice</div>
            <div className="border-b font-medium text-shadow-grey text-center">Počet ks</div>
          </div>
          <div className="table-stripped">
            {orderReturns[order.id].map(ri => (
              <div key={`return-item-row-${order.id}-${ri.uuid}`}>
                <ReturnItemRow returnItem={ri} />
              </div>
            ))}
          </div>
          <div className="p-5">
            <Btn
              title={'Vrátit položky'}
              children={'Vrátit položky'}
              isDisabled={orderReturns[order.id].length === 0 || isFetching}
              onClick={separateReturnItemsByDepotsAndReturnThem}
              cssClass="btn-outline btn-danger btn-block"
            />
          </div>
        </div>
      ) : null}
      <div>
        {oldReturnedOrders?.length > 0 ? (
          <div className="w-full">
            <div className="font-bold text-xl ml-5 mt-5">Již vrácené položky</div>
            {oldReturnedOrders.map(pmp => (
              <div key={pmp.id}>
                <div className="font-bold text-xl text-gray-400 ml-5 mt-5">
                  Vratka dne {formatDate(pmp.dateCreated)}, příjemka č. {pmp.number}
                </div>
                <div className="grid grid-cols-12 leading-10">
                  {/*HLAVICKA TABULKY*/}
                  <div className="col-start-1 col-span-6 border-b font-medium text-shadow-grey pl-20">Název zboží</div>
                  <div className="border-b font-medium text-shadow-grey">Sklad</div>
                  <div className="border-b font-medium text-shadow-grey">Expirace</div>
                  <div className="border-b font-medium text-shadow-grey text-center">Šarže</div>
                  <div className="border-b font-medium text-shadow-grey text-center">Pozice</div>
                  <div className="border-b font-medium text-shadow-grey text-center">Počet ks</div>
                </div>
                <div className="table-stripped">
                  {pmp.moves.map(pm => (
                    <div key={`product-move-row-${order.id}-${pmp.id}-${pm.id}`}>
                      <ProductMoveRow productMove={pm} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}
