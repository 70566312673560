import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { OrderItem } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { Btn } from './Btn'
import { FormInput } from './FormInput'
import { FormTextarea } from './FormTextarea'
import { useCore } from '../hooks'

type Props = {
  orderItem: OrderItem
  onUpdate: Fn
}
export const EditOrderItemModal: React.FC<Props> = ({ orderItem, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [quantity, setQuantity] = useState(orderItem.quantity)
  const [note, setNote] = useState(orderItem.note)
  const { core, isFetching, setIsFetching } = useCore()

  const closeModal = () => setIsOpen(false)

  const openModal = () => {
    setQuantity(orderItem.quantity)
    setNote(orderItem.note)
    setIsOpen(true)
  }

  const updateOrderItem = async () => {
    setIsFetching(true)
    await core?.services.order.updateOrderItemPart({ id: orderItem.id, note, quantity })
    onUpdate()
    setIsFetching(false)
    closeModal()
  }

  const deleteOrderItem = async () => {
    if (confirm('Opravdu smazat?')) {
      setIsFetching(true)
      await core?.services.order.deleteOrderItem(orderItem)
      onUpdate()
      setIsFetching(false)
      closeModal()
    }
  }

  return (
    <div>
      <Btn cssClass={'btn-primary'} icon={'fa-pen'} title={'Upravit'} onClick={openModal} isLoading={isFetching} />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog onClose={closeModal} as="div" className="fixed inset-0 z-10 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* Tento element nechat, browser vycentruje modal do prostred. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl bg-base-blue">
                <div className={'flex justify-between items-center'}>
                  <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-black">
                    Upravit položku {orderItem.name}
                  </Dialog.Title>
                  <Btn
                    cssClass={'btn-danger'}
                    icon={'fa-trash-alt'}
                    children={'Smazat položku'}
                    title={'Smazat položku'}
                    onClick={() => deleteOrderItem()}
                    isDisabled={isFetching}
                    isLoading={isFetching}
                  />
                </div>
                <div className="mt-2">
                  <div className="card">
                    <div className={'card-body'}>
                      <h2 className="text-sm font-bold">Počet kusů</h2>
                      <div className={'flex items-stretch gap-1 '}>
                        <Btn
                          cssClass={'btn-square btn-danger flex-1'}
                          children={isFetching ? '' : '-1'}
                          title={'Odebrat 1'}
                          onClick={() => setQuantity(quantity - 1)}
                          isLoading={isFetching}
                        />
                        <Btn
                          cssClass={'btn-square btn-danger flex-1'}
                          children={isFetching ? '' : '-10'}
                          title={'Odebrat 10'}
                          onClick={() => setQuantity(quantity - 10)}
                          isLoading={isFetching}
                        />
                        <FormInput
                          inputCssClass={'text-right'}
                          cssClass={'w-28 flex-1'}
                          type={'number'}
                          value={`${quantity}`}
                          onChange={q => setQuantity(Number(q))}
                        />
                        <Btn
                          cssClass={'btn-square btn-success flex-1'}
                          children={isFetching ? '' : '+1'}
                          title={'Přidat 1'}
                          onClick={() => setQuantity(quantity + 1)}
                          isLoading={isFetching}
                        />
                        <Btn
                          cssClass={'btn-square btn-success flex-1'}
                          children={isFetching ? '' : '+10'}
                          title={'Přidat 10'}
                          onClick={() => setQuantity(quantity + 10)}
                          isLoading={isFetching}
                        />
                      </div>
                    </div>
                    <FormTextarea
                      label={'Poznámka k položce'}
                      cssClass={'mt-2'}
                      value={note}
                      onChange={n => setNote(n)}
                    />
                  </div>
                </div>

                <div className="mt-4 flex justify-end gap-2">
                  <Btn
                    onClick={closeModal}
                    cssClass={'btn-outline border-light-grey'}
                    icon={'fa-ban'}
                    children={'Zrušit'}
                    title={'Zrušit'}
                    isLoading={isFetching}
                  />
                  <Btn
                    onClick={updateOrderItem}
                    isDisabled={isFetching}
                    cssClass={'btn-primary'}
                    icon={'fa-check'}
                    children={'Uložit'}
                    title={'Uložit'}
                    isLoading={isFetching}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
