import React from 'react'
import { Order, ClearanceItem } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { ClearanceItemRow } from './ClearanceItemRow'

type Props = {
  order: Order
  onUpdate: Fn
}
export const ClearanceItems: React.FC<Props> = ({ order, onUpdate }) => {
  const clearanceItems: ClearanceItem[] = [...order.clearanceItems] || []

  return (
    <div>
      <div className="grid grid-cols-12 leading-10">
        {/*HLAVICKA TABULKY*/}
        <div
          className={
            clearanceItems.filter(i => i.product?.isFragile === false && i.product.isOversize === false)
              ? 'col-start-1 col-span-4 border-b font-medium text-shadow-grey pl-20 pr-20'
              : 'col-start-1 col-span-3 border-b font-medium text-shadow-grey pl-20 pr-20'
          }>
          Název zboží
        </div>
        <div
          className={
            clearanceItems.filter(i => i.product?.isFragile === false && i.product.isOversize === false)
              ? 'hidden'
              : 'block'
          }></div>
        <div className="border-b font-medium text-shadow-grey">Kód</div>
        <div className="border-b font-medium text-shadow-grey">EAN</div>
        <div className="border-b font-medium text-shadow-grey">Expirace</div>
        <div className="border-b font-medium text-shadow-grey">Šarze</div>
        <div className="border-b font-medium text-shadow-grey">Pozice</div>
        <div className="border-b font-medium text-shadow-grey text-center">Počet ks</div>
        <div className="border-b font-medium text-shadow-grey text-center">&nbsp;</div>
        <div className="border-b font-medium text-shadow-grey text-center">
          {order.processStatus?.id === 'picking'
            ? 'Vyskladněno'
            : order.processStatus?.id === 'packing'
            ? 'Zabaleno'
            : ''}
        </div>
      </div>
      <div className="table-stripped">
        {clearanceItems.map(ci => (
          <ClearanceItemRow key={`${order.id}-${ci.id}`} clearanceItem={ci} onUpdate={onUpdate} />
        ))}
      </div>
    </div>
  )
}
