import React, { useEffect, useState } from 'react'
import { FormInput } from './FormInput'
import { Btn } from './Btn'
import { FormSelect } from './FormSelect'
import { Address as AddressType, Order } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { Loader } from './Loader'
import { useCore } from '../hooks'
import { Schema, updateOrder } from '../lib'

type Props = {
  // order: Order
  // onUpdate: Fn
}
export const Address: React.FC<Props> = () => {
  const { core, countries, orderUpdates, setOrderUpdates, currentOrder, setCurrentOrder, isFetching, setIsFetching } =
    useCore()
  const [order, setOrder] = useState<Order>(currentOrder!)
  const [address, setAddress] = useState<Partial<AddressType>>(
    new AddressType({ country: 'CZ', ...order.shippingAddress }),
  )
  const loadedAt = +new Date()

  useEffect(() => {
    if (currentOrder) {
      setOrder(currentOrder)
      const shippingAddress = new AddressType({ country: 'CZ', ...currentOrder.shippingAddress })
      setAddress(shippingAddress)
      // todo in case we need to save 'empty' address object later
      // if (!currentOrder.shippingAddress) {
      //   setOrderUpdates({ ...orderUpdates, id: order.id, shippingAddress })
      // }
    }
  }, [currentOrder?.updatedAt])

  const editAddress = (prop: string, val: any) => {
    const nextAddress = { ...address, [prop]: val }
    setAddress(nextAddress)
    const shippingAddress = new AddressType(nextAddress)
    // resolves debounced input onchange on page load wtf..
    if (loadedAt + 1000 < +new Date()) {
      setOrderUpdates({ ...orderUpdates, id: order.id, shippingAddress })
    }
  }

  const submitUpdates = async () => {
    if (orderUpdates && Object.keys(orderUpdates).length > 0) {
      await updateOrder(orderUpdates, setOrderUpdates, currentOrder!, core, getOrder)
    }
  }

  const getOrder = async () => {
    setIsFetching(true)
    const res = await core?.services.order.getById(currentOrder?.id || 0, Schema.order.detail)
    if (res && res.id > 0) {
      const o = new Order(res)
      setIsFetching(false)
      setCurrentOrder(o)
    } else {
      setIsFetching(false)
    }
  }

  if (!address) {
    return (
      <div className="w-full h-full bg-gray-50 rounded-lg">
        <Loader size="fill-container" />
      </div>
    )
  }

  return (
    <div className="box">
      <div className={'grid grid-cols-4 gap-2'}>
        <FormInput
          cssClass={'col-span-2'}
          type={'text'}
          label={'Jméno'}
          value={address.firstName}
          onChange={val => editAddress('firstName', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-2'}
          type={'text'}
          label={'Příjmení'}
          value={address.lastName}
          onChange={val => editAddress('lastName', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-2'}
          type={'text'}
          label={'Telefon'}
          value={address.phone}
          onChange={val => editAddress('phone', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-2'}
          type={'text'}
          label={'E-mail'}
          value={address.email}
          onChange={val => editAddress('email', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-4'}
          type={'text'}
          label={'Společnost'}
          value={address.companyName}
          onChange={val => editAddress('companyName', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-2'}
          type={'text'}
          label={'IČ'}
          value={address.ic}
          onChange={val => editAddress('ic', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-2'}
          type={'text'}
          label={'DIČ'}
          value={address.dic}
          onChange={val => editAddress('dic', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-4'}
          type={'text'}
          label={'Ulice a č.p.'}
          value={address.street}
          onChange={val => editAddress('street', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-1'}
          type={'text'}
          label={'PSČ'}
          value={address.zip}
          onChange={val => editAddress('zip', val)}
          onSubmit={submitUpdates}
        />
        <FormInput
          cssClass={'col-span-3'}
          type={'text'}
          label={'Město'}
          value={address.city}
          onChange={val => editAddress('city', val)}
          onSubmit={submitUpdates}
        />
        <FormSelect
          cssClass={'col-span-1'}
          label={'Země'}
          value={address.country}
          options={(countries || []).map(c => ({ label: c.name, value: c.id }))}
          onChange={val => editAddress('country', val)}
        />
        <FormInput
          cssClass={'col-span-3'}
          type={'text'}
          label={'ID pobočky'}
          value={address.branchId}
          onChange={val => editAddress('branchId', val)}
          onSubmit={submitUpdates}
        />
      </div>
    </div>
  )
}
