import { useIntersect } from '../lib/hooks/useIntersect'
import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode
}

export function RenderOnFirstViewportEntry({ children, ...props }: Props) {
  const [wasIntersected, setWasIntersected] = useState(false)
  const { measureRef, isIntersecting } = useIntersect()

  useEffect(() => {
    if (isIntersecting) {
      setWasIntersected(true)
    }
  }, [isIntersecting])

  return (
    <div ref={measureRef} {...props}>
      {wasIntersected && children}
    </div>
  )
}
