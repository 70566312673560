import React from 'react'
import { formatDate } from '../lib'

type Props = {
  date?: string
  hoursAndMins?: boolean
}
export const DateFormatted: React.FC<Props> = ({ date, hoursAndMins }) => {
  return <div>{date ? formatDate(date, hoursAndMins) : ''}</div>
  // return <div>{date ? date.substring(0, 10) : ''}</div>
  // return <div>{date ? new Date(date).toISOString().substring(0, 10) : ''}</div>
}
