import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { CarrierCurrencyPayment } from './CarrierCurrencyPayment'
import { Address } from './Address'
import { Order, Tag } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { Schema } from '../lib'
import { useCore } from '../hooks'

type SelectOption = {
  label: string
  value: string | number
}

type Props = {
  order: Order
  onUpdate: Fn
}
export const OrderShipping: React.FC<Props> = ({ order, onUpdate }) => {
  const { core, orderUpdates, setOrderUpdates, isFetching } = useCore()

  const filterTags = (val: string, options: Tag[]): SelectOption[] => {
    const tags: Tag[] = options.filter((tag: Tag) => tag.name.toLowerCase().includes(val.toLowerCase()))
    return tags.map(tag => ({ value: tag.id, label: tag.name }))
  }

  const promiseOptions = (inputValue: string): Promise<Array<SelectOption>> =>
    new Promise<SelectOption[]>(resolve => {
      core.services.tag.getList({ filters: { name: inputValue } }, Schema.basic.list).then((res: any) => {
        if (res?.items?.length > 0) {
          resolve(filterTags(inputValue, res.items))
        }
      })
    })

  const onTagsChange = async (val: SelectOption[] | any) => {
    const tags = val?.map((v: SelectOption) => new Tag({ id: v.value }))
    setOrderUpdates({ ...orderUpdates, id: order?.id, tags })
  }

  return (
    <div className={'p-main flex gap-8'}>
      <div className={'flex-1'}>
        {!isFetching && <CarrierCurrencyPayment order={order} onUpdate={onUpdate} />}
        <div>
          <div className={'box flex flex-col gap-3 mt-5'}>
            <h1 className={'text-black text-base font-semibold'}>Tagy</h1>
            <div className={'flex-1'}>
              {!isFetching && (
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultValue={order.tags.map(t => ({ label: t.name, value: t.id }))}
                  defaultOptions={true}
                  loadOptions={promiseOptions as any}
                  onChange={onTagsChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={'flex-1'}>
        <Address />
      </div>
    </div>
  )
}
