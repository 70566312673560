import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { Order as OrderType } from 'depoto-core/dist/src/entities'
import { OrderPrice } from './OrderPrice'
import { DateFormatted } from './DateFormatted'
import { ProcessStatusIcon } from './ProcessStatusIcon'
import { Link } from 'react-router-dom'
import { useCore } from '../hooks'
import { RenderOnFirstViewportEntry } from './RenderOnFirstViewportEntry'

type Props = {
  order: OrderType
}
export const Order: React.FC<Props> = ({ order }) => {
  let color: string = order.carrier?.color || 'rgba(0, 0, 0, 0.3)'
  color = color.replace('0.5', '0.3')
  const { company } = useCore()
  // @ts-ignore
  const hasChildren = company?.children?.length > 0

  useEffect(() => {
    tippy('[data-tippy-content]')
  }, [])

  return (
    <RenderOnFirstViewportEntry className={'flex flex-row'}>
      <Link
        to={`/order/${order.id}`}
        className="grid grid-cols-2 border rounded-lg p-3 flex-1"
        style={{ backgroundColor: color }}>
        {hasChildren ? (
          <div>{order.company?.name || ''}</div>
        ) : (
          <div className="font-bold">{order.reservationNumber}</div>
        )}
        <div className="place-self-end">
          <ProcessStatusIcon status={order.processStatus?.id || 'received'} />
        </div>
        {hasChildren ? (
          <div className="font-bold">{order.reservationNumber}</div>
        ) : order.note?.length > 0 || order.privateNote?.length > 0 ? (
          <div></div>
        ) : null}
        {/*{(order.note?.length > 0 || order.privateNote?.length > 0) && (*/}
        {/*  <div className="place-self-end">*/}
        {/*    <FontAwesomeIcon icon={['far', 'comment-alt-lines']} color={'rgba(255, 0, 0, 1)'} />*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{order.customer?.vip && (*/}
        {/*  <div className="place-self-end">*/}
        {/*    <div className="rounded-lg py-0.5 px-1 bg-yellow-300 font-semibold">VIP</div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className="col-span-2">{`${order.shippingAddress?.firstName || ''} ${
          order.shippingAddress?.lastName || ''
        }`}</div>
        <div>
          <OrderPrice order={order} />
        </div>
        {(order.note?.length > 0 || order.privateNote?.length > 0) && (
          <div className="place-self-end">
            <FontAwesomeIcon icon={['far', 'comment-alt-lines']} color={'rgba(255, 0, 0, 1)'} />
          </div>
        )}
        <div>
          <div className="col-start-2 place-self-end font-bold">{order.boxes}</div>
          <div className="col-start-2 place-self-end font-bold ml-auto text-right">{order.carrier?.name || ''}</div>
        </div>
        <div className="col-span-2 flex flex-row">
          <div className="place-self-end ml-auto">
            {order.note?.length > 0 && (
              <FontAwesomeIcon
                data-tippy-content={order.note}
                className="ml-2"
                icon={['far', 'comment-question']}
                color={'rgb(0,0,0)'}
              />
            )}
            {order.privateNote?.length > 0 && (
              <FontAwesomeIcon
                data-tippy-content={order.privateNote}
                className="ml-2"
                icon={['far', 'comment-exclamation']}
                color={'rgb(0,0,0)'}
              />
            )}
            <FontAwesomeIcon
              data-tippy-content={order.isPaid ? 'Zaplaceno' : 'Nezaplaceno'}
              className="ml-2"
              icon={['far', 'sack-dollar']}
              color={order.isPaid ? 'rgba(25, 255, 0, 1)' : 'rgba(255, 0, 0, 1)'}
            />
            <FontAwesomeIcon
              data-tippy-content={order.quantityUnavailable === 0 ? 'Nechybí zboží' : 'Chybí zboží'}
              className="ml-2"
              icon={['far', 'person-dolly']}
              color={order.quantityUnavailable === 0 ? 'rgba(25, 255, 0, 1)' : 'rgba(255, 0, 0, 1)'}
            />
            {order.depots.length === 1 ? (
              <FontAwesomeIcon
                data-tippy-content="Zboží z 1 skladu"
                className="ml-2"
                icon={['far', 'box']}
                color={'rgba(25, 255, 0, 1)'}
              />
            ) : (
              <FontAwesomeIcon
                data-tippy-content="Zboží z více skladů"
                className="ml-2"
                icon={['far', 'boxes-stacked']}
                color={'rgba(255, 0, 0, 1)'}
              />
            )}
          </div>
        </div>
        <div className="col-span-2 flex flex-row">
          <DateFormatted date={order.dateCreated} />
          {/*<DateFormatted date={order.dateCreated} hoursAndMins={true} />*/}
          <div className="place-self-end ml-auto">{order.shippingAddress?.country || 'CZ'}</div>
        </div>
      </Link>
    </RenderOnFirstViewportEntry>
  )
}
