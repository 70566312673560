const file = {
  id: null,
  text: null,
  originalFilename: null,
  main: null,
  mimeType: null,
  size: null,
  url: null,
  thumbnails: {
    format: null,
    mimeType: null,
    url: null,
  },
}

const orderItem = {
  id: null,
  name: null,
  batch: null,
  expirationDate: null,
  code: null,
  ean: null,
  quantity: null,
  quantityUnavailable: null, // todo? costly, but doppler needs it..
  note: null,
  clearanceItems: { id: null }, // we need this to assign notes on oItems to clItems..
  type: null,
  picked: null,
  packed: null,
  product: {
    id: null, // needed for returns
    isBundle: null,
    isFragile: null,
    isOversize: null,
    mainImage: file,
    bundleChildren: {
      id: null,
      child: {
        id: null,
        name: null,
        ean: null,
        code: null,
        mainImage: file,
        isOversize: null,
        isFragile: null,
      },
      quantity: null,
    },
    supplier: { id: null, name: null }, // don't use for returns
  },
  moves: {
    id: null,
    amount: null,
    productDepotFrom: {
      depot: { id: null },
      supplier: { id: null, name: null }, // needed for returns
    },
    productDepotTo: {
      depot: { id: null },
    },
  },
}

const clearanceItem = {
  id: null,
  amount: null,
  batch: null,
  expirationDate: null,
  picked: null,
  packed: null,
  position: null,
  product: {
    id: null,
    fullName: null,
    isFragile: null,
    isOversize: null,
    code: null,
    ean: null,
    productDepots: {
      id: null,
      batch: null,
      depot: { id: null },
      expirationDate: null,
      quantityAvailable: null,
      quantityReservation: null,
      quantityStock: null,
      position: null,
      position1: null,
      position2: null,
      position3: null,
    },
    mainImage: file,
  },
  productDepots: {
    id: null,
  },
}

export const Schema = {
  basic: {
    list: { id: null, name: null },
    get detail() {
      return {
        ...this.list,
        position: null,
      }
    },
  },
  companyCarrier: {
    list: {
      id: null,
      enable: null,
      options: null,
      carrier: { id: null, name: null, color: null, position: null, requiredShippingAddress: null },
      checkout: {
        id: null,
        name: null,
      },
      externalId: null,
    },
    get detail() {
      return {
        ...this.list,
      }
    },
  },
  currency: {
    list: { id: null, name: null },
    get detail() {
      return {
        ...this.list,
        ratio: null,
      }
    },
  },
  order: {
    list: {
      id: null,
      boxes: null,
      billNumber: null,
      reservationNumber: null,
      dateCreated: null,
      priceAll: null,
      isPaid: null,
      quantityUnavailable: null,
      carrier: {
        id: null,
        name: null,
        color: null,
        position: null,
        requiredShippingAddress: null,
      },
      currency: { id: null, name: null },
      company: { id: null, name: null },
      // customer: { id: null, firstName: null, lastName: null },
      depots: { id: null }, // todo na dev server kua!!!
      processStatus: { id: null, name: null },
      shippingAddress: { id: null, firstName: null, lastName: null, country: null },
      tags: { id: null, name: null, color: null },
      note: null,
      privateNote: null,
    },
    get detail() {
      return {
        ...this.list,
        note: null,
        privateNote: null,
        checkout: {
          id: null,
          name: null,
          depots: {
            id: null,
          },
          returnsDepot: {
            id: null,
          },
        },
        createdBy: { id: null, name: null },
        clearanceItems: clearanceItem,
        items: orderItem,
        paymentItems: {
          id: null,
          amount: null,
          payment: { id: null, name: null, type: { id: null, name: null } },
        },
        processStatusRelations: {
          status: {
            id: null,
            name: null,
          },
          note: null,
          created: null,
          createdBy: {
            id: null,
            name: null,
            email: null,
          },
        },
        shippingAddress: {
          id: null,
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          companyName: null,
          ic: null,
          dic: null,
          street: null,
          zip: null,
          city: null,
          country: null,
          branchId: null,
        },
        files: {
          id: null,
          originalFilename: null,
          text: null,
          main: null,
          mimeType: null,
          size: null,
          url: null,
          product: { id: null },
          order: { id: null },
          thumbnails: {
            format: null,
            mimeType: null,
            url: null,
          },
        },
        packages: {
          id: null,
          code: null,
          carrier: {
            id: null,
            name: null,
            requiredShippingAddress: null,
            tariffs: {
              id: null,
              name: null,
            },
          },
          // clearanceItems: clearanceItem, // todo packages should use this
          tariff: {
            id: null,
            name: null,
          },
          disposal: {
            id: null,
          },
          items: orderItem,
          statuses: {
            id: null,
            code: null,
            text: null,
            created: null,
          },
          ticketUrl: null,
          sent: null,
          weight: null,
          weightRequired: null,
          dimensionsRequired: null,
          dimensionX: null,
          dimensionY: null,
          dimensionZ: null,
        },
      }
    },
  },
  orderGroup: {
    list: { id: null, name: null },
    get detail() {
      return {
        ...this.list,
        orders: Schema.order.detail,
      }
    },
    get clearanceItems() {
      return {
        ...this.list,
        clearanceItems: clearanceItem,
      }
    },
    get forBatchUpdate() {
      return {
        ...this.list,
        orders: {
          id: null,
          reservationNumber: null,
          carrier: { id: null },
          items: {
            id: null,
            picked: null,
            packed: null,
          },
          packages: {
            id: null,
            sent: null,
            carrier: {
              id: null,
              name: null,
              requiredShippingAddress: null,
              tariffs: {
                id: null,
                name: null,
              },
            },
          },
          processStatus: {
            id: null,
          },
        },
      }
    },
  },
  package: {
    list: { id: null, code: null },
    get detail() {
      return {
        ...this.list,
        sent: null,
        ticketUrl: null,
        carrier: {
          id: null,
          name: null,
          requiredShippingAddress: null,
          tariffs: {
            id: null,
            name: null,
          },
        },
        order: {
          id: null,
          shippingAddress: {
            id: null,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            street: null,
            city: null,
            country: null,
            zip: null,
          },
        },
      }
    },
  },
  packageDisposal: {
    list: { id: null, code: null, sent: null, ticketUrl: null },
    get detail() {
      return {
        ...this.list,
        sent: null,
        carrier: {
          id: null,
          name: null,
        },
        packages: {
          id: null,
          code: null,
          order: {
            // todo server.. returning undefined order here for now..
            shippingAddress: {
              companyName: null,
              firstName: null,
              lastName: null,
            },
          },
        },
      }
    },
  },
  payment: {
    list: { id: null, name: null, type: { id: null, name: null } },
    get detail() {
      return {
        ...this.list,
        eetEnable: null,
      }
    },
  },
  productMovePack: {
    list: { id: null, dateCreated: null, url: null, note: null, number: null, type: { id: null, name: null } },
    get detail() {
      return {
        ...this.list,
        order: { id: null },
        moves: {
          id: null,
          amount: null,
          note: null,
          orderItem: {
            id: null,
            product: {
              id: null,
              name: null,
              ean: null,
              code: null,
              supplier: { id: null },
              isBundle: null,
              bundleChildren: {
                id: null,
                child: {
                  id: null,
                  name: null,
                  ean: null,
                  code: null,
                  supplier: { id: null },
                  mainImage: file,
                },
                quantity: null,
              },
            },
          },
          productDepotFrom: {
            id: null,
            batch: null,
            expirationDate: null,
            position: null,
            position1: null,
            position2: null,
            position3: null,
          },
          productDepotTo: {
            id: null,
            batch: null,
            depot: {
              id: null,
              name: null,
            },
            expirationDate: null,
            position: null,
            position1: null,
            position2: null,
            position3: null,
            product: {
              id: null,
              name: null,
              code: null,
              ean: null,
              mainImage: file,
            },
          },
        },
      }
    },
  },
  user: {
    list: { id: null, name: null },
    get detail() {
      return {
        ...this.list,
        company: {
          id: null,
          name: null,
          children: {
            id: null,
            name: null,
          },
        },
      }
    },
  },
}
