import { LOGIN } from './actions'
import { User } from 'depoto-core/dist/src/entities'

export type LoginSliceType = {
  user?: User
  isLoading: boolean
  isLoggedIn: boolean
  lastLoginTime: number
  error: string
}
export const initialState: LoginSliceType = {
  user: undefined,
  isLoading: false,
  isLoggedIn: false,
  lastLoginTime: 0,
  error: '',
}

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN.INIT:
      return {
        ...state,
        error: '',
        isLoading: true,
      }
    case LOGIN.SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        isLoggedIn: true,
        lastLoginTime: +new Date(),
      }
    case LOGIN.ERROR:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload,
      }
    case LOGIN.TERMINATE:
      return {
        ...state,
        user: undefined,
        isLoading: false,
        isLoggedIn: false,
      }
    case LOGIN.LOGOUT:
      return {
        ...state,
        user: undefined,
        isLoading: false,
        isLoggedIn: false,
      }
    default: {
      return state
    }
  }
}
