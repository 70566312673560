import React, { createContext, useContext, useReducer, useCallback } from 'react'
import { asyncer } from './middleware'
import { initialState, MainReducer, StateType } from './reducers'

interface ContextProps {
  state: StateType
  dispatch: any
}

const GlobalStore = createContext({} as ContextProps)

export const useGlobalStore = () => useContext(GlobalStore)

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  // @ts-ignore
  const [state, dispatchBase] = useReducer(MainReducer, initialState)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useCallback(asyncer(dispatchBase, state), [])

  return <GlobalStore.Provider value={{ state, dispatch }}>{children}</GlobalStore.Provider>
}
