import { useRef, useEffect } from 'react'
import { Order, OrderItem, Package, ProductBundle, ProductMove, ProductMovePack } from 'depoto-core/dist/src/entities'
import { DepotoCore } from 'depoto-core'
import { Address as AddressType } from 'depoto-core/dist/src/entities/Address'
import { Fn } from 'depoto-core/dist/src/models'
import { OrderItemForReturn, PackageUpdate } from '../store/core/reducer'
import { CommonItem } from './hooks/usePaginated'

export const wait = async (timeMs = 100) => {
  return new Promise(resolve => setTimeout(resolve, timeMs))
}

export const printTicket = async (pack: Package) => {
  // const isZasilkovna = pack.carrier && pack.carrier.id && pack.carrier.id.includes('zasilkovna') // todo test with vhfishing and olicon
  const isZasilkovna = false
  const isUlozenka = pack.carrier && pack.carrier.id && pack.carrier.id.includes('ulozenka')
  const isPpl = pack.carrier && pack.carrier.id && pack.carrier.id.includes('ppl')
  const isFedexMultipage = pack.carrier && pack.carrier.id && pack.carrier.id.includes('shippypro_fedex')
  const isPersonally = pack.carrier && pack.carrier.id && pack.carrier.id.includes('personally')
  return printDocument(
    pack.ticketUrl,
    !!isZasilkovna || !!isUlozenka || !!isPpl || !!isFedexMultipage || !!isPersonally,
  )
}

export const printDocument = async (
  url: string,
  isLandscape: boolean = false,
): Promise<
  | {
      stdout: string
      stderr: string
      error: string
      filePath: string
      fileName: string
      cmd: string
    }
  | any
> => {
  const hasError = await checkTicketError(url)
  if (hasError) {
    alert('Chyba serveru')
    return
  }
  return new Promise(resolve => {
    if (!url || url?.length === 0) {
      return resolve(null)
    }
    if (window._depoto) {
      const printer = window.localStorage.getItem('printer')
      const printerWithParams = `${printer}${isLandscape ? ' -o landscape -o fit-to-page ' : ' -o fit-to-page '}`
      window._depoto.node.printLP(url, printerWithParams, ({ stdout, stderr, error, filePath, fileName, cmd }: any) => {
        console.warn({
          stdout,
          stderr,
          error,
          filePath,
          fileName,
          cmd,
        }) // TODO: remove
        resolve({
          stdout,
          stderr,
          error,
          filePath,
          fileName,
          cmd,
        })
      })
    } else {
      const w = window.open(url)
      resolve({
        stdout: 'window.print()',
        stderr: '',
        error: '',
        filePath: url,
        fileName: '',
        cmd: '',
        window: w,
      })
      // setTimeout(() => {
      //   if (w && typeof w.print === 'function') {
      //     w.print() // same origin policy. todo workaround by postMessage()
      //   }
      // }, 500)
    }
  })
}

const checkTicketError = async (url: string) => {
  let hasError = false
  await fetch(url).then(res => (res.status !== 200 ? (hasError = true) : null))
  return hasError
}

export class NumberHelper {
  static fromString(str: string): number {
    if (!str) {
      str = ''
    }
    return Number(str.replace(',', '.').replaceAll(' ', ''))
  }

  static toString(num: number | string, decimals: number = 2): string {
    return `${Number(num).toFixed(decimals)}`
  }
}

// eh, react and the component unloading.... let's do this in router
export const useUnload = (fn: Function) => {
  const cb = useRef(fn)

  useEffect(() => {
    cb.current = fn
  }, [fn])

  useEffect(() => {
    const onUnload = (...args: any) => cb.current?.(...args)
    window.addEventListener('beforeunload', onUnload)
    return () => window.removeEventListener('beforeunload', onUnload)
  }, [])
}

export const KeyEventType = {
  BARCODE: 'BARCODE',
  POSITION: 'POSITION',
  GS1: 'GS1',
  ENTER: 'ENTER',
  ESC: 'ESC',
}

export const handleBarcodeScanner = (core: DepotoCore): void => {
  const tts = Number(window.localStorage.getItem('scanner-tts'))
  if (tts > 300 && !isNaN(tts)) {
    console.log('setting barcode scanner tts: ', tts)
    core.services.keyEvent?.stopListeners()
    core.services.keyEvent?.setTimeToScan(tts)
    core.services.keyEvent?.startListeners()
  }
  core.services.keyEvent?.onScannedBarcode.subscribe().then(barcode => {
    console.log('APP: barc ', barcode)
    // DPD ticket fuckup - remove leading '%00':
    const b = barcode?.startsWith('%00') ? barcode.replace('%00', '') : barcode
    document.dispatchEvent(new CustomEvent(KeyEventType.BARCODE, { detail: b }))
  })
  core.services.keyEvent?.onScannedPositions.subscribe().then(position => {
    console.log('APP: pos ', position)
    document.dispatchEvent(new CustomEvent(KeyEventType.POSITION, { detail: position }))
  })
  core.services.keyEvent?.onScannedGS1.subscribe().then(gs1 => {
    document.dispatchEvent(new CustomEvent(KeyEventType.GS1, { detail: gs1 }))
  })
  core.services.keyEvent?.onKeyEnter.subscribe().then(enter => {
    document.dispatchEvent(new CustomEvent(KeyEventType.ENTER, { detail: enter }))
  })
  core.services.keyEvent?.onKeyEsc.subscribe().then(escape => {
    document.dispatchEvent(new CustomEvent(KeyEventType.ESC, { detail: escape }))
  })
}

export const unregisterBarcodeScanner = (core: DepotoCore): void => {
  core.services.keyEvent?.stopListeners()
}

const chars: string[] = []
export const barcodeTestReadTimes: {
  onComplete: (res: number[]) => void
  result: number[]
} = {
  onComplete: () => null,
  result: [],
}
let lastReadTime = +new Date()
export const testBarcodeScanner = (event: KeyboardEvent) => {
  const interval = +new Date() - lastReadTime

  if (interval <= 200) {
    barcodeTestReadTimes.result.push(interval)
    barcodeTestReadTimes.onComplete(barcodeTestReadTimes.result)
  }
  lastReadTime = +new Date()
  // isBluetoothScanner = event.shiftKey && event.code?.startsWith('Digit')
  chars.push(event.key)
}

export const updateOrder = async (
  orderUpdates: Partial<Order>,
  setOrderUpdates: Fn,
  currentOrder: Partial<Order>,
  core: DepotoCore,
  onSuccess: Fn,
) => {
  const updates: any = { ...orderUpdates }
  if (updates?.shippingAddress && currentOrder) {
    let addressId = updates.shippingAddress.id
    if (addressId > 0) {
      await core?.services.address.update(updates.shippingAddress)
    } else {
      const res = await core?.services.address.create(updates.shippingAddress)
      addressId = (res && res.id) || 0
      if (addressId) {
        await core?.services.order.updateOrderPart({
          id: currentOrder.id,
          shippingAddress: new AddressType({ id: addressId }),
        })
      }
    }
  }
  delete updates.shippingAddress
  await core?.services.order.updateOrderPart(updates)
  await wait(100)
  setOrderUpdates({})
  await wait(300)
  setOrderUpdates({})
  onSuccess()
}

export const updatePackages = async (
  packagesUpdates: PackageUpdate,
  setPackageUpdates: Fn,
  core: DepotoCore,
  onSuccess: Fn,
) => {
  const updates = { ...packagesUpdates }
  for (const id in updates) {
    await core?.services.pack.updatePart({ id: Number(id), ...updates[id] })
  }
  await wait(100)
  setPackageUpdates({})
  await wait(300)
  onSuccess()
}

export type GetHeaderTitleFn = (params: {
  splitLocation: string[] | undefined
  currentOrder: Order | undefined
}) => string

export const getHeaderTitle: GetHeaderTitleFn = ({ splitLocation, currentOrder }) => {
  if (!splitLocation || splitLocation.length <= 1) return ''
  if (splitLocation[1] === 'dashboard') return splitLocation[2]
  if (splitLocation[1] === 'groups' || splitLocation[1] === 'dispatch') return splitLocation[1]
  if (splitLocation[1] === 'order') return `${currentOrder?.processStatus?.id || ''}`
  return ''
}

export const formatDate = (utcString: string, hoursAndMins = false): string => {
  const d = new Date(utcString)
  const now = new Date()
  let dtStr = ''
  if (d.getFullYear() === now.getFullYear() && d.getMonth() === now.getMonth() && d.getDate() === now.getDate()) {
    dtStr = 'dnes'
    // todo?
    hoursAndMins = true
  } else {
    dtStr = ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth() + 1)).slice(-2) + '.' + d.getFullYear()
  }
  if (hoursAndMins) {
    return (
      dtStr +
      `${dtStr === 'dnes' ? ':' : ''} ` +
      ('0' + d.getHours()).slice(-2) +
      ':' +
      ('0' + d.getMinutes()).slice(-2)
      // ':' +
      // ('0' + d.getSeconds()).slice(-2)
    )
  }
  return dtStr
}

// pull OrderItems from ProductBundle
export const getOrderItemsForReturns = (
  order: Order,
  oldReturnedPacks: ProductMovePack[] = [],
): OrderItemForReturn[] => {
  const orderItems: OrderItemForReturn[] = order.items?.filter(oi => oi.type === 'product') || []
  let i = 0
  for (const oi of orderItems) {
    if (oi?.product?.bundleChildren?.length! > 0) {
      delete orderItems[i]
      const children =
        oi.product?.bundleChildren.map(
          (bc: ProductBundle) =>
            new OrderItem({
              id: oi.id,
              name: bc.child?.name,
              product: bc.child,
              code: bc.child?.code,
              ean: bc.child?.ean,
              quantity: bc.quantity * oi.quantity,
              quantityReturned: getQuantityReturnedForOrderItem(oi.id, bc.child?.id, oldReturnedPacks),
            }),
        ) || []
      orderItems.push(...children)
    } else {
      orderItems[i].quantityReturned = getQuantityReturnedForOrderItem(oi.id, oi.product?.id, oldReturnedPacks)
    }
    i++
  }
  return orderItems.filter(oi => oi.quantity > (oi.quantityReturned || 0))
}

const getQuantityReturnedForOrderItem = (
  orderItemId: number,
  productId?: number,
  movePacks: ProductMovePack[] = [],
) => {
  if (movePacks.length === 0) {
    return 0
  }
  const returnsForOrderItem = []
  for (const pmp of movePacks) {
    returnsForOrderItem.push(
      ...pmp.moves.filter((pm: ProductMove) => pm.orderItem?.id === orderItemId && pm.product?.id === productId),
    )
  }
  console.log({ returnsForOrderItem })
  return returnsForOrderItem.map(pm => pm.amount).reduce((a, b) => a + b, 0)
}

export function mergeById<T extends CommonItem>(newItems: T[], currentItems: T[]) {
  const merged = (currentItems || []).concat(newItems)
  return merged.filter((item, index, self) => index === self.findIndex(found => found.id === item.id))
}

export const isNonEmptyValue = (value: any): boolean => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    value !== 0 &&
    (typeof value === 'number' ? !isNaN(value) : true) &&
    (Array.isArray(value) ? value.filter(isNonEmptyValue).length > 0 : true) &&
    (typeof value === 'object' ? Object.values(value).filter(isNonEmptyValue).length > 0 : true)
  )
}

export const removeEmptyProperties = (object = {}, additionalFilter: (val: unknown) => boolean = () => true) =>
  Object.fromEntries(Object.entries(object).filter(([_, value]) => isNonEmptyValue(value) && additionalFilter(value)))

export const chooseEnabledFilter = (options: (string | undefined | null | number)[]) =>
  options.reduce((opt, acc) => (isNonEmptyValue(opt) && opt !== 'all' ? opt : acc), null)
