import React, { useState } from 'react'
import { ReactComponent as Img } from '../assets/img/yin_yang.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditOrderItemModal } from './EditOrderItemModal'
import { Checkbox } from './Checkbox'
import { OrderItem, File as DepotoFile, ProductBundle, ProductMove, Product } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { useCore } from '../hooks'
import { PhotoSwipe } from './PhotoSwipe'
import { FormInput } from './FormInput'
import { EditReturnItemModal } from './EditReturnItemModal'
import { DateFormatted } from './DateFormatted'
import { Dropdown } from './Dropdown'
import { Pictogram } from './Pictogram'
import { OrderItemForReturn } from '../store/core/reducer'

type Props = {
  // returnedMoves?: any
  // movesToReturn?: Array<{
  //   amount: number
  //   batch: string
  //   expirationDate: string
  //   position1: string
  //   position2: string
  //   position3: string
  //   orderItemId: string | number
  //   productId: string | number
  // }>
  orderItem: OrderItemForReturn
  // isInPackage?: boolean
  // currentPackage?: any
  // packages?: any[]
  onUpdate: Fn
}

export const OrderItemRowForReturns: React.FC<Props> = ({
  orderItem,
  // returnedMoves,
  // movesToReturn,
  // isInPackage,
  // currentPackage,
  // packages = [],
  onUpdate,
}) => {
  // const [amountForReturn, setAmountForReturn] = useState<number>(0) // todo?
  const { core, isFetching, setIsFetching, currentOrder } = useCore()

  const thumbUrl = orderItem.product?.mainImage?.thumbnails?.length
    ? orderItem.product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = orderItem.product?.mainImage?.thumbnails?.length
    ? orderItem.product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  const processStatus = currentOrder?.processStatus?.id

  const setPicked = async (picked: boolean) => {
    setIsFetching(true)
    try {
      await core?.services.order.updateOrderItemPart({ id: orderItem.id, picked })
    } catch (errors) {
      alert(`Chyba:\n${JSON.stringify(errors)}`)
    }
    setIsFetching(false)
    onUpdate()
  }

  const setPacked = async (packed: boolean) => {
    setIsFetching(true)
    try {
      await core?.services.order.updateOrderItemPart({ id: orderItem.id, packed })
    } catch (errors) {
      alert(`Chyba:\n${JSON.stringify(errors)}`)
    }
    setIsFetching(false)
    onUpdate()
  }

  // const getReturnedForProduct = (productId?: number): { count: number; returns: any[] } => {
  //   // console.log('returnedFor', {productId, returnedMoves})
  //   if (!productId) {
  //     return { count: 0, returns: [] }
  //   }
  //   let count = 0
  //   const returns =
  //     returnedMoves?.length > 0 ? returnedMoves.filter((pm: ProductMove) => pm.product?.id === productId) : []
  //   if (returns.length > 0) {
  //     count = returns.map((pm: ProductMove) => pm.amount).reduce((a: number, b: number) => a + b)
  //   }
  //   return { count, returns }
  // }

  // const getMovesToReturnForOrderItem = (orderItemId: number, productId?: number): { count: number; returns: any[] } => {
  //   // console.log('getMovesToReturnForOrderItem', { productId, orderItemId, movesToReturn })
  //   if (!productId) {
  //     return { count: 0, returns: [] }
  //   }
  //   let count = 0
  //   // console.log({ movesToReturn, orderItemId, productId })
  //   const returns: any[] = movesToReturn?.filter(m => m.orderItemId === orderItemId && m.productId === productId) || []
  //   // movesToReturn?.length > 0 ? movesToReturn.filter((pm: ProductMove) => pm.product?.id === productId) : []
  //   if (returns.length > 0) {
  //     count = returns.map(m => m.amount).reduce((a: number, b: number) => a + b)
  //   }
  //   return { count, returns }
  // }
  // console.log(orderItem.batch, orderItem.expirationDate)

  // const moveToPackage = async (packId: number) => {
  //   console.log({ packId, packages })
  //   const items = packages.find(p => p.id === packId).items || []
  //   items.push(orderItem)
  //   const res = await core?.services.pack.update({
  //     id: packId,
  //     carrier: currentOrder?.carrier,
  //     order: currentOrder,
  //     items,
  //   })
  //   onUpdate()
  // }

  // const packIndex = packages.indexOf(currentPackage) + 1

  return (
    <>
      <div className={`grid grid-cols-12 leading-10 ${orderItem.quantityUnavailable > 0 && 'bg-red-200'}`}>
        {/*1. sloupec*/}
        <div className="grid grid-cols-6 col-span-6 gap-2">
          <div className="col-end-1 self-center flex items-center pl-20">
            {orderItem.quantityUnavailable > 0 && (
              <div className={'self-center mr-2'}>
                <span className={'label-red'} title={'Položky nejsou skladem'}>
                  <span className={'font-extrabold'}>{`${orderItem.quantityUnavailable}`}</span>
                </span>
              </div>
            )}
            {/*/!*{isBundleChild && (*!/ // not gonna happen*/}
            {/*  <div className="grid grid-cols-1 pl-2 gap-2">*/}
            {/*    <div className="col-end-1 self-center items-center">*/}
            {/*      <FontAwesomeIcon className={'text-primary-blue'} icon={['far', 'long-arrow-right']} size={'lg'} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            <PhotoSwipe
              alt={''}
              caption={`
                        ${orderItem ? orderItem.name : ''} <br> 
                        ${orderItem?.code ? 'Kód: ' + orderItem.code : ''} <br> 
                        ${orderItem?.ean ? 'EAN: ' + orderItem.ean : ''}`}
              galleryImg={imageUrl}
              src={thumbUrl}
              thumbnails={''}
            />
          </div>
          <div className="col-span-5 self-center font-semibold">
            {orderItem?.name}
            {orderItem.product?.isFragile ? <Pictogram type={'fragile'} isInline={true} marginLeft={2} /> : null}
            {orderItem.product?.isOversize ? <Pictogram type={'oversize'} isInline={true} marginLeft={2} /> : null}
          </div>
        </div>
        {/*3. sloupec*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{orderItem.code}</div>
        {/*4. sloupec*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{orderItem.ean}</div>
        {/*5. sloupec*/}
        <div className="place-self-center self-center">
          <div className="square">
            {orderItem?.product?.bundleChildren?.length! > 0 ? (
              <span className="square-amount">+</span>
            ) : (
              <span className="square-amount">{orderItem.quantity}</span>
            )}
          </div>
        </div>
        {/*5. sloupec*/}
        <div className="place-self-center self-center">
          <div className="square">
            {orderItem?.product?.bundleChildren?.length! > 0 ? (
              <span className="square-amount">+</span>
            ) : (
              <span className="square-amount">{orderItem.quantityReturned}</span>
            )}
          </div>
        </div>
        {/*5. sloupec*/}
        <div className="place-self-center self-center">
          <div className="square">
            {orderItem?.product?.bundleChildren?.length! > 0 ? (
              <span className="square-amount">+</span>
            ) : (
              <span className="square-amount">{orderItem.quantity - (orderItem.quantityReturned || 0)}</span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className={'place-self-center self-center'}>
            {!orderItem.product?.isBundle ? (
              <EditReturnItemModal
                orderItem={orderItem}
                // onReturn={onReturn}
                // isDisabled={getReturnedForProduct(orderItem.product?.id).count >= orderItem.quantity} // todo delete whole row
              />
            ) : (
              <div>VIRTUAL</div>
            )}
          </div>
        </div>
      </div>
      {orderItem.note?.length > 0 && (
        <div className="flex flex-row justify-between pl-10 pr-10">
          <div className="w-full text-red-600">{orderItem.note}</div>
        </div>
      )}
      {orderItem?.product?.bundleChildren?.length! > 0 &&
        orderItem?.product?.bundleChildren.map((bc: ProductBundle) => (
          <OrderItemRowForReturns
            key={`${currentOrder?.id}-${orderItem.id}-${bc.id}`}
            orderItem={
              new OrderItem({
                id: orderItem.id,
                name: bc.child?.name,
                product: bc.child,
                code: bc.child?.code,
                ean: bc.child?.ean,
                quantity: bc.quantity * orderItem.quantity,
              })
            }
            onUpdate={onUpdate}
          />
        ))}
    </>
  )
}
