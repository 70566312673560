import React from 'react'
import { Btn } from './Btn'
import { ProcessStatusIcon } from './ProcessStatusIcon'
import { translate } from '../lib'
import { Order } from 'depoto-core/dist/src/entities'
import { DateFormatted } from './DateFormatted'

type Props = {
  order: Order
}
export const OrderStateHistory: React.FC<Props> = ({ order }) => {
  const processStatusColor: any = {
    received: {
      bg: 'bg-cloud-blue',
      btn: 'primary-blue',
    },
    recieved: {
      bg: 'bg-cloud-blue',
      btn: 'primary-blue',
    }, // server typo
    picking: {
      bg: 'bg-violet',
      btn: 'purple',
    },
    packing: {
      bg: 'bg-indigo',
      btn: 'indigo-dark',
    },
    packed: {
      bg: 'bg-sky-light',
      btn: 'sky-blue',
    },
    dispatched: {
      bg: 'bg-orange-light',
      btn: 'orange',
    },
    delivered: {
      bg: 'bg-green-light',
      btn: 'green',
    },
    returned: {
      bg: 'bg-red-light',
      btn: 'red',
    }, // todo
    picking_error: {
      bg: 'bg-red-light',
      btn: 'red',
    },
    cancelled: {
      bg: 'bg-red-light',
      btn: 'red',
    }, // todo
  }
  return (
    <div className={'flex flex-col gap-4'}>
      <h1 className={'text-black text-xl font-bold'}>Historie stavů</h1>
      <div className={'box flex flex-col gap-3'}>
        {order.processStatusRelations.map(ps => (
          <div
            key={`${order.id}-${ps.status.id}-${ps.created}-${ps.createdBy?.email}-${Math.random() * 999}`}
            className={`p-4 flex justify-between items-center rounded-md ${processStatusColor[ps.status.id].bg}`}>
            <div className={'flex items-center gap-3'}>
              <Btn
                cssClass={`btn-square text-white pointer-events-none bg-${
                  processStatusColor[ps.status.id].btn
                } border-${processStatusColor[ps.status.id].btn}`}>
                <ProcessStatusIcon status={ps.status.id} size="lg" />
              </Btn>
              <div>
                <h1 className={`text-sm text-${processStatusColor[ps.status.id].btn} font-semibold`}>
                  {translate(ps.status.id)}
                </h1>
                <h1 className={`text-xs text-${processStatusColor[ps.status.id].btn} font-normal`}>
                  <DateFormatted date={ps.created} hoursAndMins={true} />
                </h1>
              </div>
            </div>
            <div className={'flex flex-col'}>
              {ps.createdBy && ps.createdBy.name?.length > 0 && (
                <h1 className={`text-sm text-${processStatusColor[ps.status.id].btn} font-semibold`}>
                  {ps.createdBy?.name || ''} ({ps.createdBy?.email || ''})
                </h1>
              )}
              <h1 className={`text-xs text-${processStatusColor[ps.status.id].btn} font-normal self-end`}>
                {ps.note ? `Pozn: ${ps.note}` : ''}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
