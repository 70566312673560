export const CARRIER = {
  DEFAULT: 'default',
  GLS: 'gls',
  PPL: 'ppl',
  ZASILKOVNA: 'zasilkovna',
}

export class PackageBarcodeUtil {
  static getPackageCodeFromBarcode(code: string): string {
    try {
      switch (this.guessCarrierFromBarcode(code)) {
        default:
        case CARRIER.DEFAULT:
          return code
        case CARRIER.GLS:
          return code.substr(0, code.length - 1)
        case CARRIER.PPL:
          return code.substr(0, code.length - 1)
        // return code.substr(0, -11)
        case CARRIER.ZASILKOVNA:
          return code.substr(1)
      }
    } catch (e) {
      console.warn(e)
    }
    return code
  }

  static guessCarrierFromBarcode(code: string): string {
    if (`${code[0]}${code[1]}` === '00' && code.length === 20) {
      return CARRIER.DEFAULT
    }
    if (!!code && (code[0] === 'Z' || code[0] === 'Y')) {
      return CARRIER.ZASILKOVNA
    } else if (!!code && code.length > 11) {
      if (code[0] === '2' || code[0] === '4' || code[0] === '8') {
        return CARRIER.PPL
      } else if (`${code[0]}${code[1]}` === '90' || `${code[0]}${code[1]}` === '00') {
        return CARRIER.GLS
      }
    }
    return CARRIER.DEFAULT
  }
}
