export const translate = (key: string): string => {
  // console.warn(key, strings[key]); // TODO: remove
  return strings[key] || ''
}

export const strings: { [key: string]: string } = {
  appName: 'Depoto Expedice',
  received: 'Přijaté objednávky',
  recieved: 'Přijaté objednávky', // server typo
  picking: 'Vyskladnění',
  packing: 'Balení',
  packed: 'Zabaleno',
  dispatched: 'Předáno dopravci',
  delivered: 'Doručeno',
  returned: 'Nedoručeno (Vráceno)',
  picking_error: 'Chyba vyskladnění',
  cancelled: 'Zrušeno',
  groups: 'Skupiny',
  dispatch: 'Svozy',
}
