import React, { useEffect, useRef } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { Btn } from './Btn'
import { FormInput } from './FormInput'
import { useCore } from '../hooks'
import { KeyEventType } from '../lib'
import { FormTextarea } from './FormTextarea'
import { FormSelect } from './FormSelect'
import { EVENT_SHOW_NOTIFICATION } from './Notification'
import { OrderItemForReturn, ReturnItem } from '../store/core/reducer'
import { generateUuidV4 } from 'depoto-core/dist/src/utils'

type Props = {
  isDisabled?: boolean
  orderItem: OrderItemForReturn
}
export const EditReturnItemModal: React.FC<Props> = ({ isDisabled, orderItem }) => {
  const { isFetching, currentOrder, depots, orderReturns, setOrderReturns } = useCore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const oItemFromOrder = currentOrder?.items.find(oi => orderItem.id === oi.id) // on packages.items are not moves ... server fault, is in schema...
  const returnsDepotId =
    currentOrder?.checkout?.returnsDepot && currentOrder.checkout.returnsDepot.id > 0
      ? currentOrder.checkout.returnsDepot.id
      : oItemFromOrder?.moves?.[0]?.productDepotFrom?.depot?.id
      ? oItemFromOrder?.moves[0].productDepotFrom.depot.id
      : depots?.[0]?.id
  const [pos1, setPos1] = useState<string>(orderItem.moves[0]?.position1 || '')
  const [pos2, setPos2] = useState<string>(orderItem.moves[0]?.position2 || '')
  const [pos3, setPos3] = useState<string>(orderItem.moves[0]?.position3 || '')
  const [returnItem, setReturnItem] = useState<ReturnItem>({
    uuid: generateUuidV4(),
    amount: orderItem.quantity - (orderItem.quantityReturned || 0) || 0,
    depotId: Number(returnsDepotId),
    batch: orderItem.batch || '',
    expirationDate: orderItem.expirationDate || '',
    position1: orderItem.moves[0]?.position1 || '',
    position2: orderItem.moves[0]?.position2 || '',
    position3: orderItem.moves[0]?.position3 || '',
    orderItem,
    product: orderItem.product?.name?.length! > 0 ? orderItem.product : { ...orderItem.product, name: orderItem.name },
    note: orderItem.note || '',
  })

  const inputAmountRef = useRef<any>(null)

  useEffect(() => {
    inputAmountRef?.current?.blur() // this POS will fire as empty on init, as inputRef on destroy! what the fuckin fuck????
  }, [inputAmountRef.current])

  useEffect(() => {
    const nextReturnItem = { ...returnItem }
    nextReturnItem.uuid = generateUuidV4()
    setReturnItem(nextReturnItem)
  }, [isOpen])

  useEffect(() => {
    document.addEventListener(KeyEventType.BARCODE, processCode)
    return () => {
      document.removeEventListener(KeyEventType.BARCODE, processCode)
    }
  }, [])

  useEffect(() => {
    // fucking hack wtf
    if (pos1 !== returnItem.position1) {
      updateReturnItem('position1', pos1)
    }
    if (pos2 !== returnItem.position2) {
      updateReturnItem('position2', pos2)
    }
    if (pos3 !== returnItem.position3) {
      updateReturnItem('position3', pos3)
    }
  }, [returnItem, pos1, pos2, pos3])

  const processCode = (c: CustomEvent | any) => {
    const barcode = c?.detail
    // handle rare occasion, when Order has more than one item with same ean / code - shopify fuckup
    const isCodeUnique =
      currentOrder &&
      currentOrder.items.filter(
        oi => oi.code === barcode || oi.product?.code === barcode || oi.ean === barcode || oi.product?.ean === barcode,
      ).length === 1
    if (!isCodeUnique) {
      console.log('code not unique on Order!')
      document.dispatchEvent(
        new CustomEvent(EVENT_SHOW_NOTIFICATION, {
          detail: {
            message: 'Naskenovaný kód není v objednávce unikátní!',
            type: 'warning',
          },
        }),
      )
      return
    }
    if (barcode === (orderItem.ean || orderItem.product?.ean)) {
      console.log('processCode: ', barcode, orderItem.name, orderItem.id, { orderItem })
      openModal()
    }
    if (barcode === (orderItem.code || orderItem.product?.code)) {
      console.log('processCode: ', barcode, orderItem.name, orderItem.id, { orderItem })
      openModal()
    }
  }

  const processPosition = (c: CustomEvent | any) => {
    if (c?.detail?.length === 3) {
      const pos = c?.detail
      // setReturnItem({...returnItem, position1: pos[0], position2: pos[1], position3: pos[2]}) // nope, cached returnItem from init here
      setPos1(pos[0])
      setPos2(pos[1])
      setPos3(pos[2])
    }
  }

  function closeModal() {
    setIsOpen(false)
    document.removeEventListener(KeyEventType.POSITION, processPosition)
    document.removeEventListener(KeyEventType.ESC, closeModal)
  }

  function openModal() {
    setIsOpen(true)
    document.addEventListener(KeyEventType.POSITION, processPosition)
    document.addEventListener(KeyEventType.ESC, closeModal)
  }

  const updateReturnItem = (prop: string, val: any) => {
    const nextReturnItem: ReturnItem | any = { ...returnItem }
    nextReturnItem[prop] = val
    setReturnItem(nextReturnItem)
  }

  const confirmReturn = () => {
    const nextOrderReturns = { ...orderReturns }
    const orderId = orderItem.order?.id || currentOrder?.id
    if (orderId && orderId > 0) {
      // const uniqueReturnsForOrder = nextOrderReturns[orderId]
      //   ? nextOrderReturns[orderId].filter(
      //       ri => ri.orderItem?.id !== returnItem.orderItem.id && ri.product?.id !== returnItem.product?.id,
      //     )
      //   : []

      const existingReturnsForOrderItem = nextOrderReturns[orderId]
        ? nextOrderReturns[orderId].filter(
            ri => ri.orderItem?.id === returnItem.orderItem.id && ri.product?.id === returnItem.product?.id,
          )
        : []
      const alreadyReturnedAmount = existingReturnsForOrderItem.map(ri => ri.amount).reduce((a, b) => a + b, 0)
      console.log({ existingReturnsForOrderItem, alreadyReturnedAmount })
      // allow for same orderItem return to multiple depots etc. check sum!!!
      if (alreadyReturnedAmount + returnItem.amount > (orderItem?.quantity || 0) - (orderItem?.quantityReturned || 0)) {
        document.dispatchEvent(
          new CustomEvent(EVENT_SHOW_NOTIFICATION, {
            detail: {
              message: `Není možné vrátit požadovaný počet kusů, maximální možné množství k vrácení je ${
                (orderItem?.quantity || 0) - (orderItem?.quantityReturned || 0) - alreadyReturnedAmount
              }ks`,
              type: 'warning',
            },
          }),
        )
      } else {
        const uniqueReturnsForOrder = nextOrderReturns[orderId] ? [...nextOrderReturns[orderId]] : []
        uniqueReturnsForOrder.push(returnItem)
        nextOrderReturns[orderId] = uniqueReturnsForOrder
        setOrderReturns(nextOrderReturns)
      }
    }
    closeModal()
  }

  return (
    <>
      <Btn
        cssClass={'btn-primary'}
        icon={'fa-undo'}
        title={'Vrátit'}
        onClick={openModal}
        isLoading={isFetching}
        isDisabled={isDisabled}
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog onClose={closeModal} as="div" className="fixed inset-0 z-10 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* Tento element nechat, browser vycentruje modal do prostred. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl bg-base-blue">
                <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-black">
                  Vyplnit vratku '{orderItem.name}'
                </Dialog.Title>
                <div className="mt-2">
                  <div className="card">
                    <div className="card-body gap-2">
                      <div className={'flex flex-row gap-2'}>
                        <FormInput
                          // ref={inputAmountRef}
                          // inputRef={(r: any) => inputAmountRef.current = r}
                          cssClass={'flex-1'}
                          type={'number'}
                          inputCssClass={'text-right'}
                          label={'Množství'}
                          value={`${returnItem.amount}`}
                          min={0}
                          max={orderItem.quantity}
                          blurOnInit={isOpen}
                          onChange={(val: any) => updateReturnItem('amount', Number(val))}
                          onSubmit={confirmReturn}
                        />
                        <FormInput
                          cssClass={'flex-1'}
                          type={'date'}
                          inputCssClass={'text-left'}
                          label={'Expirace'}
                          value={`${returnItem.expirationDate}`}
                          onChange={val => updateReturnItem('expirationDate', val)}
                          onSubmit={confirmReturn}
                        />
                        <FormInput
                          cssClass={'flex-1'}
                          type={'text'}
                          inputCssClass={'text-right'}
                          label={'Šarže'}
                          value={`${returnItem.batch}`}
                          onChange={val => updateReturnItem('batch', val)}
                          onSubmit={confirmReturn}
                        />
                      </div>
                      <div className={'flex flex-row gap-2'}>
                        <FormInput
                          cssClass={'flex-1'}
                          type={'text'}
                          inputCssClass={'text-right'}
                          label={'Pozice 1'}
                          value={`${returnItem.position1}`}
                          onChange={val => setPos1(val)}
                          // onChange={val => updateReturnItem('position1', val)}
                          onSubmit={confirmReturn}
                        />
                        <FormInput
                          cssClass={'flex-1'}
                          type={'text'}
                          inputCssClass={'text-right'}
                          label={'Pozice 2'}
                          value={`${returnItem.position2}`}
                          onChange={val => setPos2(val)}
                          // onChange={val => updateReturnItem('position2', val)}
                          onSubmit={confirmReturn}
                        />
                        <FormInput
                          cssClass={'flex-1'}
                          type={'text'}
                          inputCssClass={'text-right'}
                          label={'Pozice 3'}
                          value={`${returnItem.position3}`}
                          onChange={val => setPos3(val)}
                          // onChange={val => updateReturnItem('position3', val)}
                          onSubmit={confirmReturn}
                        />
                      </div>
                      <div className={'flex flex-row items-center'}>
                        <div className={'mr-5 font-bold'}>Sklad:</div>
                        <FormSelect
                          options={depots.map(d => ({ label: d.name, value: d.id }))}
                          value={returnItem.depotId || 0}
                          onChange={val => updateReturnItem('depotId', Number(val))}
                          // label={'Sklad'}
                          cssClass={'w-64'}
                        />
                      </div>
                      <div className={'flex flex-row gap-2'}>
                        <FormTextarea
                          label={'Poznámka'}
                          cssClass={'w-full'}
                          textAreaCssClass={'h-56 w-full'}
                          value={returnItem.note}
                          onChange={val => updateReturnItem('note', val)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex justify-end gap-2">
                  <Btn
                    onClick={closeModal}
                    isLoading={isFetching}
                    cssClass={'btn-outline border-light-grey'}
                    icon={'fa-ban'}
                    children={'Zrušit'}
                    title={'Zrušit'}
                  />
                  <Btn
                    onClick={confirmReturn}
                    cssClass={'btn-primary'}
                    icon={'fa-check'}
                    children={'Nastavit'}
                    title={'Nastavit'}
                    isLoading={isFetching}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
