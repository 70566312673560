import React from 'react'
import { Order, OrderItem } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { OrderItemRow } from './OrderItemRow'

type Props = {
  order: Order
  onUpdate: Fn
}
export const OrderItems: React.FC<Props> = ({ order, onUpdate }) => {
  const orderItems: OrderItem[] = order.items?.filter(oi => oi.type === 'product') || []

  return (
    <div>
      <div className="grid grid-cols-12 leading-10">
        {/*HLAVICKA TABULKY*/}
        <div className="col-start-1 col-span-6 border-b font-medium text-shadow-grey pl-20">Název zboží</div>
        <div className="border-b"></div>
        <div className="border-b font-medium text-shadow-grey">Kód</div>
        <div className="border-b font-medium text-shadow-grey">EAN</div>
        <div className="border-b font-medium text-shadow-grey text-center">Počet ks</div>
        <div className="border-b"></div>
        <div className="border-b"></div>
      </div>
      <div className="table-stripped">
        {orderItems.map(oi => (
          <OrderItemRow
            key={`order-item-${order.id}-${oi.id}`}
            orderItem={oi}
            onUpdate={onUpdate}
            onReturn={() => null}
          />
        ))}
      </div>
    </div>
  )
}
