import { CommonPaginator, usePaginated } from './usePaginated'
import { Order, OrderGroup } from 'depoto-core/dist/src/entities'
import { useCallback, useMemo, useState } from 'react'
import { removeEmptyProperties } from '../index'
import { useCore } from '../../hooks'

// !+!+!+!+!+!+!+!+!+!+
// Module was moved from RN Terminal and must be moved to depoto-core eventually
// Please do not remove unknown and "unused" functions.
// !+!+!+!+!+!+!+!+!+!+

// raw from BE
export type OrderFilters = Partial<{
  fulltext: string
  reservationNumber: number
  billNumber: number
  notes: string
  checkouts: [number]
  depots: [number]
  onlyDepots: [number]
  payments: [number]
  status: string
  processStatus: string
  processStatusChange: string
  processStatusUpdated: string
  isPaid: boolean
  carrier: string
  carriers: [string]
  deleted: boolean
  dateCreated: string
  dateBill: string
  dateExpedition: string
  updated: string
  companies: [number]
  box: string
  item: string
  package: string
  customer: number
  customerFulltext: string
  shippingCountries: [string]
  externalId: string
  user: number
  tags: [number]
  hasAvailableProducts: boolean
  group: number
  groups: [number]
  createdBy: [number]

  // Client
  hideEmpty: boolean
  userId: number // only for order groups
}>

const clientFilterKeys = ['hideEmpty', 'userId']

interface Params {
  filters?: OrderFilters
  sort?: string
  direction?: string
  onlyMyProductGroups?: boolean // exceptional functionality for some clients
  schema?: any
}

export function usePaginatedOrders(params: Params = {}) {
  const { filters, onlyMyProductGroups, schema = { id: null }, sort = 'dateExpedition', direction = 'ASC' } = params
  const { core } = useCore()

  const serverFilters = useMemo(
    () =>
      removeEmptyProperties(
        Object.fromEntries(Object.entries(filters || {}).filter(([key]) => !clientFilterKeys.includes(key))),
      ),
    [filters],
  )

  const fetchOrderGroups = useCallback(
    async (pageNo: number) => {
      const params: any = {
        direction: 'ASC',
        filters: {
          user: filters?.userId,
          ...(filters?.fulltext?.length && filters?.fulltext?.length > 0 ? { name: filters?.fulltext } : {}),
          ...(filters?.processStatus?.length &&
            (filters?.processStatus?.length > 0 ? { processStatus: filters?.processStatus } : {})),
        },
        page: pageNo,
        sort: 'name',
      }

      const { items, paginator } = (await core.services.order.getOrderGroups(params, {
        id: null,
        orders: schema,
      })) as unknown as {
        items: OrderGroup[]
        paginator: CommonPaginator
      }

      let newOrders = items.reduce<Order[]>((ac, orderGroup) => [...ac, ...(orderGroup.orders || [])] as Order[], [])

      if (filters?.processStatus) {
        newOrders = newOrders.filter(order => order.processStatus?.id === filters.processStatus)
      }

      return {
        items: newOrders,
        paginator,
      }
    },
    [core.services.order, filters?.fulltext, filters?.processStatus, filters?.userId],
  )

  const fetchOrders = useCallback(
    async (page: number) => {
      const params: any = removeEmptyProperties({
        direction,
        filters: serverFilters,
        page,
        sort: sort,
      })

      return (await core.services.order.getList(params, schema)) as {
        items: Order[]
        paginator: CommonPaginator
      }
    },
    [core.services.order, serverFilters, sort],
  )

  const clientFilter = useCallback(
    (order: Order) => {
      if (filters?.hideEmpty) {
        return order.clearanceItems.length > 0
      }

      return true
    },
    [filters?.hideEmpty],
  )

  const {
    isLastPage,
    loadMore,
    loading,
    refresh,
    items: orders,
  } = usePaginated<Order>({
    queryKey: ['orders', filters, sort, direction],
    clientFilter,
    fetchItems: onlyMyProductGroups ? fetchOrderGroups : fetchOrders,
  })

  return {
    isLastPage,
    loadMore,
    loading,
    orders,
    refresh,
  }
}
