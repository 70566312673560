import React from 'react'
import { Fn } from 'depoto-core/dist/src/models'

interface Props {
  value?: string
  onChange: Fn
  cssClass?: string
  label?: string
  placeholder?: string
  textAreaCssClass?: string
}

export const FormTextarea: React.FC<Props> = ({ value, onChange, cssClass, label, placeholder, textAreaCssClass }) => {
  return (
    <div className={cssClass === undefined ? 'form-control' : cssClass + ' form-control '}>
      <label>{label}</label>
      <textarea
        className={textAreaCssClass === undefined ? 'textarea' : textAreaCssClass + ' textarea'}
        placeholder={placeholder}
        value={value}
        onChange={ev => (typeof onChange === 'function' ? onChange(ev.target.value) : null)}></textarea>
    </div>
  )
}
