import React from 'react'
import { Order } from 'depoto-core/dist/src/entities'

type Props = {
  order: Order
}
export const OrderPrice: React.FC<Props> = ({ order }) => {
  let currency: string
  switch (order.currency?.id) {
    default:
    case 'CZK':
      currency = 'Kč'
      break
    case 'EUR':
      currency = '€'
      break
    case 'USD':
      currency = '$'
      break
  }
  return order.currency?.id === 'EUR' || order.currency?.id === 'USD' ? (
    <div className="col-span-2 font-bold">
      {currency} {Number(order.priceAll || 0).toFixed(2)}
    </div>
  ) : (
    <div className="col-span-2 font-bold">
      {Number(order.priceAll || 0).toFixed(2)} {currency}
    </div>
  )
}
