import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { Btn } from './Btn'
import { FormInput } from './FormInput'
import { wait } from '../lib'
import { ClearanceItem, ClearanceItemLocation, ProductDepot } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { FormSelect } from './FormSelect'
import { generateUuidV4 } from 'depoto-core/dist/src/utils'
import { useCore } from '../hooks'

type Props = {
  clearanceItem: ClearanceItem
  onUpdate: Fn
}
export const EditExpeditionBatchPositionModal: React.FC<Props> = ({ clearanceItem, onUpdate }) => {
  let [isOpen, setIsOpen] = useState<boolean>(false)
  const [locations, setLocations] = useState<(ClearanceItemLocation | any)[]>(clearanceItem.locations || [])
  const { core, currentOrder, depots, isFetching, setIsFetching } = useCore()

  const allowedDepotIds = currentOrder?.checkout?.depots.map(d => d.id) || []
  const availableDepots = depots?.filter(d => allowedDepotIds.includes(d.id))
  const productDepots =
    clearanceItem.product?.productDepots
      // .filter(pd => pd.quantityAvailable >= clearanceItem.amount && allowedDepotIds.includes(pd.depot!.id))
      .filter(pd => allowedDepotIds.includes(pd.depot!.id))
      .map(pd => ({
        ...pd,
        name: `P: ${pd.position.length > 2 ? pd.position : '-'} | Š: ${pd.batch ?? '-'} | E: ${
          pd.expirationDate ?? '-'
        }  (${pd.quantityStock} / ${pd.quantityReservation} / ${pd.quantityAvailable}ks)`,
      })) || []

  const setInitLocations = async () => {
    const productDepotId = clearanceItem.productDepots[0].id
    const productDepot = productDepots.filter(pd => pd.id === productDepotId)[0]
    if (!productDepot) {
      alert('Chybna objednavka')
      return
    }
    const loc = {
      uuid: generateUuidV4(),
      depot: productDepot.depot!.id,
      productDepot: productDepot.id,
      batch: productDepot.batch,
      expirationDate: productDepot.expirationDate,
      amount: clearanceItem.amount,
      position1: productDepot.position1,
      position2: productDepot.position2,
      position3: productDepot.position3,
    }
    setLocations([loc])
    await wait()
    return productDepot.depot!.id
  }

  const setDepot = (depotId: number | string, location: ClearanceItemLocation | any) => {
    if (!location) {
      return
    }
    depotId = Number(depotId)
    location.availableProductDepots = productDepots.filter(pd => pd.depot?.id === depotId)
    locations.forEach((l, i) => {
      if (l.uuid === location.uuid) {
        locations[i].depot = depotId
      }
    })
    if (location.availableProductDepots.length > 0) {
      const pd = location.availableProductDepots.filter((d: ProductDepot) => d.id == location.productDepot)[0]
      setProductDepot(pd?.id, location)
    } else {
      setProductDepot(undefined, location)
    }
  }

  const setProductDepot = (productDepotId: number | string | undefined, location: ClearanceItemLocation | any) => {
    const productDepot = productDepots.filter(pd => pd.id === Number(productDepotId))[0]
    const nextLocations = [...locations]
    nextLocations.forEach((l, i) => {
      if (l.uuid === location.uuid) {
        nextLocations[i].productDepot =
          productDepotId && Number(productDepotId) > 0 ? Number(productDepotId) : undefined
        nextLocations[i].batch = productDepot?.batch
        nextLocations[i].expirationDate = productDepot?.expirationDate
        nextLocations[i].position1 = productDepot?.position1
        nextLocations[i].position2 = productDepot?.position2
        nextLocations[i].position3 = productDepot?.position3
        nextLocations[i].maxAmount = productDepot?.quantityStock || 0
      }
    })
    setLocations(nextLocations)
  }

  const setAmount = (amount: number, location: ClearanceItemLocation | any) => {
    const nextLocations = [...locations]
    nextLocations.forEach((l, i) => {
      if (l.uuid === location.uuid) {
        nextLocations[i].amount =
          Number(amount) <= nextLocations[i].maxAmount ? Number(amount) : nextLocations[i].maxAmount
      }
    })
    setLocations(nextLocations)
  }

  const update = async () => {
    const nextLocations = []
    for (const loc of locations) {
      if (loc.depot > 0 && loc.productDepot > 0) {
        nextLocations.push(loc)
      } else {
        alert('Chyba - Sklady a lokace musi byt vyplnene!')
        console.warn('error', loc) // TODO: remove
        return
      }
    }
    if (clearanceItem.amount !== locations.map(l => l.amount).reduce((a, b) => Number(a) + Number(b))) {
      alert(`Nesouhlasi pocty. Celkem je treba vyuzit ${clearanceItem.amount}ks`)
      return
    }
    const nextClearanceItem = {
      id: clearanceItem.id,
      order: currentOrder!.id,
      locations: nextLocations.map(l => {
        const res = { ...l }
        Object.keys(res).forEach((k, i) => {
          if (!res[k] || k === 'availableProductDepots' || k === 'productDepot' || k === 'uuid' || k === 'maxAmount') {
            delete res[k]
          }
        })
        return res
      }),
    }
    setIsFetching(true)
    await core?.services.order.updateClearanceItemLocations(nextClearanceItem)
    setIsFetching(false)
    await wait()
    onUpdate()
    closeModal()
  }

  const addRow = () => {
    const nextLocations = [...locations]
    nextLocations.push({
      uuid: generateUuidV4(),
      depot: '',
      productDepot: '',
      batch: '',
      expirationDate: '',
      amount: '',
      position1: '',
      position2: '',
      position3: '',
    })
    setLocations(nextLocations)
  }

  const removeRow = (location: ClearanceItemLocation | any) => {
    const nextLocations = locations.filter(loc => loc.uuid !== location.uuid)
    setLocations(nextLocations)
  }

  const closeModal = () => setIsOpen(false)

  const openModal = async () => {
    const pddId = await setInitLocations() // unnecessary async resolves multiple state update issue..
    setDepot(pddId!, locations[0])
    setIsOpen(true)
  }

  useEffect(() => {
    setInitLocations() // fix: fill the productDepots select on load.. meh
  }, [])

  return (
    <>
      <Btn cssClass={'btn-primary'} icon={'fa-pen'} onClick={openModal} title={'Upravit'} isLoading={isFetching} />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog onClose={closeModal} as="div" className="fixed inset-0 z-10 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* Tento element necha browser vycentrocat modal do prostred. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl bg-base-blue">
                <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-black">
                  Upravit položku
                </Dialog.Title>
                <div className="mt-2">
                  <div className="card">
                    <div className="card-body gap-2">
                      {locations.map((loc, i) => (
                        <div key={loc.uuid}>
                          <div className={'flex flex-row flex-1 gap-2'}>
                            <FormSelect
                              cssClass={'flex-1'}
                              label={'Sklad'}
                              value={loc.depot}
                              options={(availableDepots || []).map(pd => ({ label: pd.name, value: pd.id }))}
                              onChange={val => setDepot(val, loc)}
                            />
                            <FormSelect
                              cssClass={'flex-1'}
                              label={'Sklad'}
                              value={loc.productDepot}
                              options={(loc.availableProductDepots || []).map((pd: any) => ({
                                label: pd.name,
                                value: pd.id,
                              }))}
                              onChange={val => setProductDepot(val, loc)}
                            />
                            <FormInput
                              cssClass={'flex-1'}
                              inputCssClass={'text-right'}
                              label={'Pocet'}
                              type={'number'}
                              max={loc.maxAmount}
                              value={loc.amount}
                              onChange={val => setAmount(val, loc)}
                            />
                          </div>
                          <div className={'flex gap-2 items-center mt-4'}>
                            {i > 0 && (
                              <Btn
                                cssClass={'btn-danger btn-circle'}
                                icon={'minus'}
                                title={'Odebrat'}
                                isLoading={isFetching}
                                onClick={() => removeRow(loc)}
                              />
                            )}
                            {i === locations.length - 1 && (
                              <Btn
                                cssClass={'btn-success btn-circle'}
                                icon={'plus'}
                                title={'Přidat'}
                                isLoading={isFetching}
                                onClick={addRow}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex justify-end gap-2">
                  <Btn
                    onClick={closeModal}
                    cssClass={'btn-outline border-light-grey'}
                    icon={'fa-ban'}
                    children={'Zrušit'}
                    title={'Zrušit'}
                    isLoading={isFetching}
                  />
                  <Btn
                    cssClass={'btn-primary'}
                    icon={'fa-check'}
                    children={'Uložit'}
                    title={'Uložit'}
                    isDisabled={isFetching}
                    isLoading={isFetching}
                    onClick={update}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
