import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fn } from 'depoto-core/dist/src/models'
import { Btn } from './Btn'
import { FormTextarea } from './FormTextarea'
import { Dropdown } from './Dropdown'

const errorMsgs = [
  { id: 'empty', name: 'Prázdné' },
  { id: 'missing_address', name: 'Chybí doručovací adresa' },
  { id: 'missing_branch_id', name: 'Chybí číslo pobočky' },
  { id: 'missing_items', name: 'Chybí zboží' },
]
type Props = {
  isShown?: boolean
  isError?: boolean
  note?: string
  onUpdate: Fn
}
type State = {
  isOpen: boolean
  note: string
}
export class NoteModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
      note: '',
    }
  }

  closeModal = () => this.setState({ isOpen: false })

  openModal = () => this.setState({ isOpen: true, note: this.props.note || '' })

  updateNote = async () => {
    this.props.onUpdate(this.state.note)
    this.closeModal()
  }

  render() {
    const { isOpen, note } = this.state
    return (
      <div>
        {/*<Btn cssClass={'btn-primary'} icon={'fa-note'} onClick={() => this.openModal()} />*/}

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog onClose={() => this.closeModal()} as="div" className="fixed inset-0 z-10 overflow-y-auto">
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* Tento element nechat, browser vycentruje modal do prostred. */}
              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div
                  className={`${
                    !this.props.isError && 'overflow-hidden '
                  } inline-block w-full max-w-xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl bg-base-blue`}>
                  <div className={'flex justify-between items-center'}>
                    <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-black">
                      {this.props.isError ? 'Upravit chybovou poznámku' : 'Upravit poznámku'}
                    </Dialog.Title>
                  </div>
                  <div className="mt-2">
                    <div className="card">
                      <FormTextarea
                        label={'Poznámka'}
                        cssClass={'mt-2'}
                        value={note}
                        onChange={n => this.setState({ note: n })}
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end gap-2">
                    <div className="mr-12 mt-2">
                      <Dropdown
                        title={'Předpřipravené'}
                        options={errorMsgs}
                        hasAllOption={false}
                        onChange={val =>
                          this.setState({ note: val === 'empty' ? '' : errorMsgs.find(m => m.id === val)?.name || '' })
                        }
                      />
                    </div>
                    {/*<Btn*/}
                    {/*  onClick={() => this.closeModal()}*/}
                    {/*  cssClass={'btn-outline border-light-grey'}*/}
                    {/*  icon={'fa-ban'}*/}
                    {/*  children={'Zrušit'}*/}
                    {/*/>*/}
                    <Btn
                      onClick={() => this.updateNote()}
                      cssClass={this.props.isError ? 'btn-danger' : 'btn-primary'}
                      icon={'fa-check'}
                      children={this.props.isError ? 'Nahlásit chybu' : 'Uložit'}
                      title={this.props.isError ? 'Nahlásit chybu' : 'Uložit'}
                    />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    )
  }
}
