export type KVMap = {
  [key: string]: any
}

export default function bindActions(actions: any, dispatch: any): KVMap | any {
  const bindAction = (action: any, dispatchA: any) => {
    return function () {
      return dispatchA(action.apply(null, arguments))
    }
  }
  if (typeof actions === 'function') {
    return bindAction(actions, dispatch)
  }
  if (typeof actions !== 'object' || actions === null) {
    throw new Error(
      `bindActions expected an object or a function, instead received ${actions === null ? 'null' : typeof actions}. `,
    )
  }
  const boundActions: KVMap = {}
  for (const key in actions) {
    const action: any = actions[key]
    if (typeof action === 'function') {
      boundActions[key] = bindAction(action, dispatch)
    }
  }
  return boundActions
}
