import React from 'react'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

type Props = {
  alt: string
  caption?: string
  galleryImg: string
  src: string
  thumbnails: any
}
export const PhotoSwipe: React.FC<Props> = ({ alt, caption, galleryImg, src, thumbnails }) => {
  return (
    <Gallery>
      <Item original={galleryImg} thumbnail={thumbnails} width="1024" height="768" title={caption}>
        {({ ref, open }) => (
          <div>
            <div className={'image-wrapper'}>
              <img
                className={'image'}
                ref={ref as React.MutableRefObject<HTMLImageElement>}
                onClick={open}
                src={src}
                alt={alt}
              />
            </div>
          </div>
        )}
      </Item>
    </Gallery>
  )
}
